import { getParagraphStyle, unit } from '@aubade/core/ui-kit'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button as ChakraButton,
  HStack,
  Icon,
  Tooltip,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react'
import type { As, IconProps } from '@chakra-ui/react'
import { useEvent, useIsBusy } from '@nartex/react-libs'
import { useMutation } from '@tanstack/react-query'
import type { To } from 'history'
import type {
  AnchorHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  ComponentProps,
  MouseEvent,
} from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useTranslatable } from '../useTranslatable'

import {
  getIsDangerousStyleMap,
  getVariantStyleMap,
  iconVariantStyleMap,
} from './variantsMap'

export type Variant =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'text'
  | 'circle'
  | 'circleNegative'
  | 'circleList'
  | 'circleWhite'

export type ChakraButtonProps = ComponentProps<typeof ChakraButton>

export type Tint = 'white' | 'lightGrey'

export type ButtonProps = {
  label?: TranslationKey
  'aria-label'?: TranslationKey
  iconColor?: string
  variant: Variant
  newTab?: boolean
  tint?: Tint
  to?: To
  leftIcon?: As
  rightIcon?: As
  isDangerous?: boolean
  extraIconProps?: IconProps
  onClick?: (event: MouseEvent<HTMLElement>) => void | Promise<any>
} & Pick<
  ChakraButtonProps,
  | 'isDisabled'
  | 'isLoading'
  | 'type'
  | 'id'
  | 'form'
  | 'autoFocus'
  | 'isActive'
  | 'color'
> &
  Pick<AnchorHTMLAttributes<any>, 'download'>

const COMMON_BUTTON_STYLE: ChakraButtonProps = {
  fontWeight: '600',
  borderRadius: '16px',
  color: 'white',
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(function Button(props, ref) {
  const {
    variant = 'primary',
    leftIcon,
    rightIcon,
    children,
    isDisabled,
    autoFocus,
    isActive,
    type,
    'aria-label': ariaLabel,
    isDangerous,
    iconColor,
    tint = 'lightGrey',
    extraIconProps,
    ...rest
  } = useTranslatable(props)

  const translate = useTranslate()

  const variantStyle = getVariantStyleMap(tint)[variant]

  const iconVariantStyle = iconVariantStyleMap(iconColor)[variant]

  const buttonStyles = {
    ...COMMON_BUTTON_STYLE,
    ...variantStyle,
    leftIcon: leftIcon ? (
      <Icon
        height={'15px'}
        width={'15px'}
        as={leftIcon}
        {...iconVariantStyle}
        {...extraIconProps}
      />
    ) : undefined,
    rightIcon: rightIcon ? (
      <Icon
        height={'15px'}
        width={'15px'}
        as={rightIcon}
        color="darkGrey.500"
      />
    ) : undefined,
    iconSpacing: variant.includes('circle') ? 0 : '8px',
    ...(isDangerous ? getIsDangerousStyleMap()[variant] : {}),
    isDisabled,
    title:
      variant === 'circle'
        ? undefined
        : typeof children === 'string'
          ? children
          : undefined,
    maxWidth: 'full',
  } satisfies ChakraButtonProps

  const isActiveStyle = {
    ...buttonStyles._focus,
    boxShadow: 'var(--chakra-shadows-outline)',
  } as ChakraButtonProps

  const buttonBehavior = useButtonBehavior(props)

  const buttonProps: ChakraButtonProps = {
    ...rest,
    'aria-label': ariaLabel ? translate(ariaLabel) : undefined,
    ...buttonBehavior,
    type,
    _focus: autoFocus ? isActiveStyle : {},
    ...(isActive ? isActiveStyle : {}),
  }

  // Can't use the native autoFocus feature, because it fires too early for useOnClickOutside
  const innerRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (autoFocus) {
      requestAnimationFrame(() => {
        innerRef?.current?.focus?.()
      })
    }
  }, [autoFocus])

  const buttonRef = useMergeRefs(ref, innerRef)

  if (variant === 'circle') {
    const translatedAriaLabel = buttonProps['aria-label']
    return (
      <Tooltip
        label={translatedAriaLabel}
        isDisabled={!children && !translatedAriaLabel}
      >
        <ChakraButton
          ref={buttonRef}
          variant={'link'}
          {...buttonProps}
          {...getParagraphStyle({ fontWeight: 'normal' })}
          label={undefined}
          gap={2}
        >
          <ChakraButton as={'span'} {...buttonStyles} />
          {children}
        </ChakraButton>
      </Tooltip>
    )
  }

  return (
    <ChakraButton ref={buttonRef} {...buttonStyles} {...buttonProps}>
      {children}
    </ChakraButton>
  )
})

export type GetButtonBehaviorParams = {
  to?: To
  onClick?: (event: MouseEvent<HTMLElement>) => void | Promise<any>
  isDisabled?: boolean
  newTab?: boolean
}
export function getButtonBehavior(params: GetButtonBehaviorParams) {
  const { onClick, to, isDisabled: disabled, newTab = true } = params

  if (to) {
    if (disabled) {
      return { disabled } satisfies ChakraButtonProps & { disabled?: boolean }
    }

    if (typeof to === 'string') {
      if (/^(https?:\/\/)/.test(to) || to.startsWith('blob:')) {
        return {
          as: 'a' as any,
          href: to,
          role: 'link',
          onClick,
          disabled,
          target: isSameOrigin(window.location, to) || !newTab ? '' : '_blank',
        } satisfies ChakraButtonProps & { disabled?: boolean }
      }
    }

    return {
      as: RouterLink as any,
      to,
      role: 'link',
      onClick,
      disabled,
    } satisfies ChakraButtonProps & { disabled?: boolean }
  }

  if (onClick) {
    return {
      as: 'button' as any,
      onClick,
      role: 'button',
      type: 'button',
      disabled,
    } satisfies ChakraButtonProps & { disabled?: boolean }
  }

  return { disabled } satisfies ChakraButtonProps & { disabled?: boolean }
}

export function useButtonBehavior(
  params: GetButtonBehaviorParams & { isLoading?: boolean },
) {
  const { onClick, isLoading, ...rest } = params

  const mutation = useMutation({
    async mutationFn(promise: Promise<void>) {
      return await promise
    },
  })

  useIsBusy(mutation.isLoading)

  const stableOnClick: typeof onClick = useEvent((event) => {
    const val = onClick?.(event)

    if (val instanceof Promise) {
      mutation.mutate(val)
    }
  })

  return {
    ...getButtonBehavior({
      ...rest,
      onClick: onClick ? stableOnClick : undefined,
    }),
    isLoading: mutation.isLoading || isLoading,
  }
}

function isSameOrigin(linkA: Location | string, linkB: Location | string) {
  if (String(linkA).startsWith('blob:') || String(linkB).startsWith('blob:')) {
    return false
  }

  const urlA = new URL(String(linkA))
  const urlB = new URL(String(linkB))
  return urlA.origin === urlB.origin
}

type ConfirmButtonsProps = Omit<ButtonProps, 'onClick'>

type ButtonWithConfirmProps = {
  buttonProps: ConfirmButtonsProps
  dialogProps?: { title: TranslationKey; children: ReactNode }
  onConfirm: () => void | Promise<void>
  confirmButtonProps: ConfirmButtonsProps
}

export function ButtonWithConfirm(props: ButtonWithConfirmProps) {
  const { buttonProps, ...rest } = props

  return (
    <WithConfirm
      {...rest}
      renderButton={useCallback(
        (onClick) => (
          <Button {...buttonProps} onClick={onClick} />
        ),
        [buttonProps],
      )}
    />
  )
}

type WithConfirmProps = Omit<ButtonWithConfirmProps, 'buttonProps'> & {
  renderButton: (onClick: () => void) => ReactNode
}

export function WithConfirm(props: WithConfirmProps) {
  const { renderButton, dialogProps, onConfirm, confirmButtonProps } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const translate = useTranslate()

  const onClick = dialogProps ? onOpen : onConfirm

  const button = useMemo(() => {
    return renderButton(onClick)
  }, [onClick, renderButton])

  return (
    <>
      {button}
      {dialogProps && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          trapFocus={false}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {translate(dialogProps.title)}
              </AlertDialogHeader>

              <AlertDialogBody>{dialogProps.children}</AlertDialogBody>

              <AlertDialogFooter>
                <HStack
                  spacing={unit('20')}
                  width="full"
                  justifyContent={'flex-start'}
                >
                  <Button
                    {...confirmButtonProps}
                    onClick={async () => {
                      await onConfirm()
                      onClose()
                    }}
                  />
                  <Button
                    ref={cancelRef}
                    variant="secondary"
                    onClick={onClose}
                    label="buttons.cancel"
                  />
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  )
}
