// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconDelete = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M45 2.77778H35.25L32.4643 0H18.5357L15.75 2.77778H6V8.33333H45M8.78571 44.4444C8.78571 45.9179 9.3727 47.3309 10.4175 48.3728C11.4624 49.4147 12.8795 50 14.3571 50H36.6429C38.1205 50 39.5376 49.4147 40.5825 48.3728C41.6273 47.3309 42.2143 45.9179 42.2143 44.4444V11.1111H8.78571V44.4444Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconDelete)
export default Memo
