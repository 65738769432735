import {
  useQueryParamsState,
  drawerQueryParams,
  ReadOnlyProvider,
  useReadOnly,
} from '@aubade/core/libs'
import {
  FormDrawer,
  Form,
  Paragraph,
  useForm,
  Button,
  makeForm,
} from '@aubade/core/ui-kit'
import type { FormSubmitProps, FormMode } from '@aubade/core/ui-kit'
import { DrawerContent } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { CampaignApiWrite } from '@aubade/types'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useUrls } from 'src/App'
import { v4 } from 'uuid'

import { TargetSection } from '../Publications/Components'

export function CreateEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page, id } = modalState

  const matchCreate = page === 'create'
  const match = Boolean(matchCreate || id)

  return (
    <FormDrawer isOpenProps={Boolean(match)} variant={'solid'}>
      <DrawerContent>
        {matchCreate && <FormContent />}
        {id && <FormContent id={id} />}
      </DrawerContent>
    </FormDrawer>
  )
}

type FormContentProps = {
  id?: string
}

const { Input } = makeForm<CampaignApiWrite>()

export function FormContent(props: FormContentProps) {
  const { id } = props

  const { saveButtonProps, ...methods } = useCampaignForm(id)

  const title = id
    ? 'campaign.title.showCampaign'
    : 'campaign.title.createCampaign'

  return (
    <>
      <Paragraph fontWeight="bold" size="lg" color="black.base" text={title} />
      <Form {...methods}>
        <ReadOnlyProvider value={Boolean(id)}>
          <Content {...saveButtonProps} />
        </ReadOnlyProvider>
      </Form>
    </>
  )
}

function Content(props: FormSubmitProps) {
  const translate = useTranslate()

  const readOnly = useReadOnly()

  return (
    <>
      <Input.Text
        label="conversations.title.name"
        name="name"
        required
        readOnly={readOnly}
      />
      <TargetSection target="conversation" readOnly={readOnly} />
      <Input.TextArea
        name="content"
        label="conversations.field.content"
        placeholder={translate('message.writeMessage')}
        required
        readOnly={readOnly}
      />
      {!readOnly && (
        <Button variant="primary" {...props} label="actions.send" />
      )}
    </>
  )
}

function useCampaignForm(id?: string) {
  const queryClient = useQueryClient()

  const urls = useUrls()

  const newId = useMemo(() => {
    if (id) return undefined
    return v4() as string
  }, [id])

  const useFormProps: FormMode = id
    ? { mode: 'update', id }
    : {
        mode: 'create',
      }

  return useForm<CampaignApiWrite>({
    ...useFormProps,
    resource: 'campaign',
    hookFormOptions: {
      defaultValues: {
        newId,
        isNational: true,
      },
    },
    redirect() {
      return urls.aubade().campaign()
    },
    mutationOptions: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: (query) => {
            const { queryKey } = query
            return queryKey.includes('summary/conversations')
          },
        })
      },
    },
    blockNavigation: false,
  })
}
