import type { BaseRecord, CrudFilters } from '@aubade/core/adapters'
import { useDateFnsLocale } from '@aubade/core/libs'
import type {
  UseFiltersSearchParamsReturn,
  GetRowProps,
} from '@aubade/core/ui-kit'
import { ResourceList, Separator } from '@aubade/core/ui-kit'
import type { GridProps } from '@chakra-ui/react'
import type { ColumnDef } from '@tanstack/react-table'
import { format, startOfDay, endOfDay } from 'date-fns'
import { dayValueToDate } from 'src/libs/calendarUtils'

import type { PublicationFilterType } from '../Resources/Publications/filters/types'

type ListFilterProps<ListType extends Record<string, any>> = {
  listFilters: UseFiltersSearchParamsReturn<PublicationFilterType, ListType>
  columns: ColumnDef<ListType>[]
  resource: 'publications' | 'notifications'
  templateColumns: GridProps['templateColumns']
  getRowProps?: GetRowProps<ListType>
}

export function MultiList<ListType extends BaseRecord>(
  props: ListFilterProps<ListType>,
) {
  const { listFilters, columns, resource, templateColumns, getRowProps } = props
  const { crudFilters, filtersStore } = listFilters
  const { publishedAt } = filtersStore.state
  const locale = useDateFnsLocale()
  const formattedDate = publishedAt
    ? // @ts-expect-error any
      format(new Date(dayValueToDate(publishedAt)), `EEEE dd LLLL yyyy`, {
        locale,
      })
    : undefined

  const crudTodayFilters = [
    ...crudFilters,
    {
      field: 'publishedAt',
      operator: 'between',
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
  ] as CrudFilters<ListType>

  const crudBeforeFilters = [
    ...crudFilters,
    {
      field: 'publishedAt',
      operator: 'lt',
      value: startOfDay(new Date()),
    },
  ] as CrudFilters<ListType>

  const crudAfterFilters = [
    ...crudFilters,
    {
      field: 'publishedAt',
      operator: 'gt',
      value: endOfDay(new Date()),
    },
  ] as CrudFilters<ListType>

  return (
    <>
      {publishedAt ? (
        <>
          <Separator paddingX={'60px'} />
          <ResourceList<ListType>
            dataProviderName="default"
            filters={crudFilters}
            sort={[{ field: 'sort_value', order: 'desc' }]}
            scope={resource}
            // @ts-expect-error any
            resource={resource}
            title={{
              mainTitle: `${resource}.tabletitle.date`,
              extraTitle: formattedDate!,
            }}
            tableProps={{
              templateColumns,
              columns,
              noDataLabel: `table.noData.${resource}`,
              getRowProps,
            }}
          />
        </>
      ) : (
        <>
          <Separator paddingX={'60px'} />
          <ResourceList<ListType>
            dataProviderName="default"
            filters={crudTodayFilters}
            sort={[{ field: 'sort_value', order: 'desc' }]}
            scope={resource}
            // @ts-expect-error any
            resource={resource}
            title={`${resource}.tabletitle.today`}
            tableProps={{
              templateColumns,
              columns,
              getRowProps,
            }}
          />
          <Separator paddingX={'60px'} />
          <ResourceList<ListType>
            dataProviderName="default"
            filters={crudAfterFilters}
            sort={[{ field: 'sort_value', order: 'asc' }]}
            // @ts-expect-error any
            resource={resource}
            scope={resource}
            title={`${resource}.tabletitle.future`}
            pageLimit={5}
            tableProps={{
              templateColumns,
              columns,
              getRowProps,
            }}
          />
          <Separator paddingX={'60px'} />
          <ResourceList<ListType>
            filters={crudBeforeFilters}
            sort={[{ field: 'sort_value', order: 'desc' }]}
            // @ts-expect-error any
            resource={resource}
            scope={resource}
            title={`${resource}.tabletitle.before`}
            pageLimit={5}
            tableProps={{
              templateColumns,
              columns,
              getRowProps,
            }}
          />
        </>
      )}
    </>
  )
}
