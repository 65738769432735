// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconFeed = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 0C7.14543 0 6.25 0.89543 6.25 2V7.5H43.75V2C43.75 0.895431 42.8546 0 41.75 0H8.25ZM2 10C0.895431 10 0 10.8954 0 12V48C0 49.1046 0.895432 50 2 50H48C49.1046 50 50 49.1046 50 48V12C50 10.8954 49.1046 10 48 10H2Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconFeed)
export default Memo
