// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconForbidden = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25 8.92849e-05C18.3695 8.92849e-05 12.0108 2.63399 7.32243 7.32252C2.6339 12.0107 0 18.3697 0 25C0 31.6303 2.6339 37.9892 7.32243 42.6776C12.0106 47.3661 18.3697 50 25 50C31.6303 50 37.9891 47.3661 42.6775 42.6776C47.366 37.9894 49.9999 31.6303 49.9999 25C49.9901 18.3727 47.3531 12.0198 42.6667 7.33324C37.9806 2.64685 31.6275 0.00982134 24.9999 0L25 8.92849e-05ZM4.26175 24.9999C4.26384 19.7851 6.23128 14.7625 9.77161 10.933C13.3119 7.10422 18.1651 4.75005 23.3635 4.33987C28.5622 3.92971 33.725 5.49361 37.8214 8.7201L8.71983 37.8216C5.83066 34.1725 4.25943 29.6541 4.26112 24.9994L4.26175 24.9999ZM25 45.7381C20.3149 45.7447 15.767 44.1571 12.1027 41.2369L41.2311 12.1086C44.4847 16.1996 46.0727 21.3683 45.6768 26.5807C45.2806 31.7928 42.9302 36.6628 39.0959 40.2154C35.2616 43.7676 30.2267 45.7407 24.9996 45.7382L25 45.7381Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconForbidden)
export default Memo
