import { useHTTPClient } from '@aubade/core/adapters'
import { useToastMessage } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import type { ExtendedConversation } from '@aubade/types'
import type { Tool, Document } from '@aubade/types/api'
import { useQueryMetaData } from '@nartex/api-platform'
import { useQueryClient } from '@tanstack/react-query'
import { saveAs } from 'file-saver'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useUrls } from 'src/App'

type BlockCrafterPayload = {
  newId: string
  craftCompany?: string
}

type AvailablePayload = {
  isAvailable: boolean
}

export function useAubadeQueryBuilder() {
  const queryClient = useQueryClient()
  const toast = useToastMessage()
  const httpClient = useHTTPClient()
  const navigate = useNavigate()
  const metaData = useQueryMetaData()
  const [searchParams, setSearchParams] = useSearchParams()
  const { id: me } = useConnectedUser()

  const urls = useUrls()

  return {
    // -----Publications-----
    toggleHidePublication(
      isBlocked: boolean,
      societyId: string,
      publicationId: string,
    ) {
      const blockedUrl = isBlocked ? 'unblocked' : 'blocked'
      const successMessage = isBlocked
        ? 'notifications.unblockedPublication'
        : 'notifications.blockedPublication'
      return {
        mutationFn(): Promise<any> {
          return httpClient.request({
            method: 'put',
            url: `/posts/${blockedUrl}/${societyId}/${publicationId}`,
            data: {},
            headers: metaData?.headers,
          })
        },
        async onSuccess() {
          navigate(urls.aubade().publications())
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return (
                queryKey.includes('publications') &&
                queryKey.includes('getList')
              )
            },
          })
          toast('success', successMessage)
        },
        onError(error: Error) {
          toast('error', error.message)
        },
      }
    },
    deletePublication(id: string) {
      return [
        {
          resource: 'publications',
          id,
        },
        {
          async onSuccess() {
            navigate(urls.aubade().publications())
            await queryClient.invalidateQueries({
              predicate: (query) => {
                const { queryKey } = query
                return queryKey.includes('publications')
              },
            })
            toast('success', 'notifications.deletedPublication')
          },
        },
      ] as const
    },
    // -----Conversations-----
    deleteConversation(conversationId: string, userId: string) {
      return {
        mutationFn(): Promise<any> {
          return httpClient.request({
            method: 'put',
            url: `/conversations/leave/${conversationId}/users/${userId}`,
            data: {},
            headers: metaData?.headers,
          })
        },

        async onSuccess() {
          await queryClient.invalidateQueries({
            queryKey: ['getOne', 'conversations'],
            refetchType: 'all',
          })
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('summary/conversations')
            },
          })
          toast('success', 'notifications.deleteConversation')
          searchParams.delete('id')
          setSearchParams(searchParams)
        },
        onError(error: Error) {
          toast('error', error.message)
        },
      }
    },
    blockCrafter(payload: BlockCrafterPayload) {
      return {
        mutationFn(): Promise<ExtendedConversation> {
          return httpClient.request({
            method: 'post',
            url: `/black_lists`,
            data: payload,
            headers: metaData?.headers,
          })
        },
        async onSuccess() {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('summary/conversations')
            },
          })
          toast('success', 'notifications.blockCrafter')
        },
        onError(error: Error) {
          toast('error', error.message)
        },
      }
    },
    unblockCrafter(id: string) {
      return [
        {
          resource: 'black_lists',
          id,
        },
        {
          async onSuccess() {
            await queryClient.invalidateQueries({
              predicate: (query) => {
                const { queryKey } = query
                return queryKey.includes('summary/conversations')
              },
            })
            toast('success', 'notifications.unblockCrafter')
          },
        },
      ] as const
    },
    togglaAvailable() {
      return {
        mutationFn(payload: AvailablePayload): Promise<any> {
          return httpClient.request({
            method: 'put',
            url: `/users/${me}/setAvailability`,
            data: payload,
            headers: metaData?.headers,
          })
        },

        async onSuccess() {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('users') && queryKey.includes('getOne')
            },
          })
          toast('success', 'notifications.statusChanged')
        },
        onError(error: Error) {
          toast('error', error.message)
        },
      }
    },
    renameConversation(conversationId: string) {
      return {
        mutationFn(payload: { name: string }): Promise<any> {
          return httpClient.request({
            method: 'put',
            url: `/conversations/${conversationId}/rename`,
            data: payload,
            headers: metaData?.headers,
          })
        },

        async onSuccess() {
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return (
                queryKey.includes('conversations') ||
                queryKey.includes('summary/conversations')
              )
            },
          })
        },

        suspense: true,
      }
    },
    getMessageMedia(document: Document.MessageRead, pictureUrl?: string) {
      const { mimeType, displayName } = document

      const isPicture = mimeType.includes('image')
      return {
        async mutationFn() {
          const response = await httpClient.get<string>(pictureUrl!, {
            headers: metaData?.headers,
            responseType: 'blob',
          })
          const fileBlob = new Blob([response.data], { type: mimeType })

          if (isPicture) {
            const _url = window.URL.createObjectURL(fileBlob)
            window.open(_url, '_blank')
          }
          saveAs(fileBlob, displayName)
        },
        onError() {
          toast('error', 'notifications.createError')
        },
        onSuccess() {
          toast('success', 'notifications.fileDownload')
        },
      }
    },
    // -----Tools-----
    toggleIsPublished(isPublished: boolean) {
      const publishUrl = isPublished ? 'unpublished' : 'published'

      const successMessage = isPublished
        ? 'notifications.maskedtool'
        : 'notifications.publishedtool'
      return {
        mutationFn(toolId: string): Promise<Tool.Write> {
          return httpClient.request({
            method: 'put',
            url: `/tools/${publishUrl}/${toolId}`,
            data: { isPublished: !isPublished },
            headers: metaData?.headers,
          })
        },

        async onSuccess() {
          toast('success', successMessage)
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('tools')
            },
          })
        },
        onError(error: Error) {
          toast('error', error.message)
        },
      }
    },
    // -----Notifications-----
    deleteNotification(notificationId: string) {
      return [
        {
          resource: 'notifications',
          id: notificationId,
        },
        {
          async onSuccess() {
            await queryClient.invalidateQueries({
              predicate: (query) => {
                const { queryKey } = query
                return queryKey.includes('notifications')
              },
            })
          },
        },
      ] as const
    },
    // -----Fonctions-----
    deleteFunction(functionId: string) {
      return [
        {
          resource: 'user_functions',
          id: functionId,
        },
        {
          async onSuccess() {
            navigate(urls.aubade().functions())
            await queryClient.invalidateQueries({
              predicate: (query) => {
                const { queryKey } = query
                return queryKey.includes('user_functions')
              },
            })
          },
        },
      ] as const
    },
  }
}
