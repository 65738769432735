import type { SearchFilter } from '@aubade/core/adapters'
import type { GetRowProps } from '@aubade/core/ui-kit'
import {
  Surface,
  unit,
  Paragraph,
  Title,
  Table,
  useResourceList,
} from '@aubade/core/ui-kit'
import type {
  ExtendedConversation,
  ExtendedConversationList,
} from '@aubade/types'
import type { User } from '@aubade/types/api'
import { Box, HStack, VStack, Flex } from '@chakra-ui/react'
import type { LogicalFilter } from '@nartex/data-provider'
import { useMemo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useUrls } from '../../../App'
import { ListHeader } from '../../../components'
import { CreateConversationButton } from '../Components/CreateConversationButton'
import { ConversationListFilter } from '../filters/FilterForm'
import { useConversationFilters } from '../filters/useConversationFilters'

import { useGetColumns } from './useGetColumns'

const templateColumns = '300px 1fr 1fr 1fr 140px'

export function ConversationList() {
  const { crudFilters, filtersStore } = useConversationFilters('summary')

  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      paddingX={unit('10')}
      paddingY={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
    >
      <ListHeader
        scope="summary"
        filters={
          <ConversationListFilter
            onChange={filtersStore.setState}
            filters={filtersStore.state as any}
          />
        }
        mainAction={<CreateConversationButton />}
      />

      <MultiResultList crudFilters={crudFilters} />
    </Surface>
  )
}

export function NoData() {
  return (
    <HStack width="full" height={unit('60')}>
      <Paragraph size="sm" fontWeight="bold" text={'-'} />
    </HStack>
  )
}

type ListProps = {
  crudFilters: LogicalFilter<User.Read>[]
}

function MultiResultList(props: ListProps) {
  const { crudFilters } = props
  const columns = useGetColumns()
  const urls = useUrls()

  const [searchParams] = useSearchParams()

  const memoSearch = useMemo(() => {
    return searchParams.get(`summary.q`)
  }, [searchParams])

  const listFilters = useMemo(() => {
    const search = searchParams.get(`summary.q`)
    if (search) {
      const qFilter: SearchFilter = { q: search }
      if (crudFilters) {
        return [qFilter, ...crudFilters]
      }
      return [qFilter]
    }
    return crudFilters
  }, [searchParams, crudFilters])

  const [[data]] = useResourceList<any>({
    resource: 'summary/conversations',
    filters: listFilters,
    sort: [{ field: 'sort_value', order: 'desc' }],
    hasPagination: true,
    scope: 'summary',
    dataProviderName: 'default',
  })

  const { users, messages } = data as unknown as ExtendedConversationList

  const getRowProps = useCallback<GetRowProps<ExtendedConversation>>(
    (row) => {
      return {
        to: urls.aubade().conversationDetail(row.id),
      }
    },
    [urls],
  )

  return (
    <>
      <VStack gap={'30px'} alignItems="flex-start" width="full">
        <Box paddingX={unit('60')} width="full">
          {memoSearch && data && (
            <Title.H4
              color="darkGrey.500"
              fontWeight="bold"
              text="conversations.list.title.collaborators"
            />
          )}
        </Box>

        <Flex
          direction="column"
          gap={6}
          width={'full'}
          paddingLeft={unit('10')}
        >
          <Table
            templateColumns={templateColumns}
            columns={columns}
            noDataLabel="table.noData.conversation"
            getRowProps={useCallback<GetRowProps<ExtendedConversation>>(
              (row) => {
                return {
                  to: urls.aubade().conversationDetail(row.id),
                }
              },
              [urls],
            )}
            data={users}
          />
        </Flex>
      </VStack>
      {memoSearch && data && (
        <VStack gap={'30px'} alignItems="flex-start" width="full">
          <Box paddingX={unit('60')} width="full">
            <Title.H4
              color="darkGrey.500"
              fontWeight="bold"
              text="conversations.list.title.content"
            />
          </Box>

          <Flex
            direction="column"
            gap={6}
            width={'full'}
            paddingLeft={unit('10')}
          >
            <Table
              templateColumns={templateColumns}
              columns={columns}
              noDataLabel="table.noData.conversation"
              getRowProps={getRowProps}
              data={messages}
            />
          </Flex>
        </VStack>
      )}
    </>
  )
}
