import {
  useForm,
  Form,
  Button,
  useToastMessage,
  unit,
  ButtonWithConfirm,
  Paragraph,
} from '@aubade/core/ui-kit'
import { HStack } from '@chakra-ui/react'
import { cleanHydraId, useDelete } from '@nartex/data-provider/react'
import { run } from '@nartex/stdlib'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUrls } from 'src/App'

import { useTranslate } from '../../../../translation/src'
import type { ExtendedUser } from '../../../../types/src'
import { useConnectedUser } from '../../adapters'
import { DrawerContent, isAdmin, isAgency } from '../../components'

import {
  FormContent,
  FormHeader,
  FormProfile,
  FormUserList,
} from './Components'
import { useGetKeycloakUrlAction } from './Components/useGetKeycloakUrlAction'

type FormProps = {
  id: string
  profile: 'customer' | 'collaborater'
}
export function FormEdit(props: FormProps) {
  const { id, profile } = props
  const { id: me, role } = useConnectedUser()
  const urls = useUrls()
  const translate = useTranslate()

  const isMyProfile = cleanHydraId(id) === cleanHydraId(me)

  const { saveButtonProps, ...methods } = useForm<ExtendedUser>({
    mode: 'update',
    resource: 'users',
    id,
    hookFormOptions: {
      defaultValues: {},
    },
    redirect() {
      return urls.aubade().collaboraters()
    },
    blockNavigation: false,
  })

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const toast = useToastMessage()

  const [mutateDelete] = useDelete()
  async function onDelete() {
    await mutateDelete(
      {
        resource: 'users',
        id,
      },

      {
        async onSuccess() {
          navigate(urls.aubade().customers())
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('users')
            },
          })
          toast('success', 'notifications.deletedUser')
        },
        async onError() {
          navigate(urls.aubade().customers())
          await queryClient.invalidateQueries({
            predicate: (query) => {
              const { queryKey } = query
              return queryKey.includes('users')
            },
          })
          toast('success', 'notifications.deletedUser')
        },
      },
    )
  }

  const { firstname, lastname, acRoles } = methods.getValues()
  const userRole = useMemo(() => acRoles?.AC, [acRoles?.AC])
  const fullname = [firstname, lastname].filter(Boolean).join(' ')

  const actualUrl = window.location.href
  const changePasswordUrl = useGetKeycloakUrlAction(
    actualUrl,
    'UPDATE_PASSWORD',
  )

  const confirmText =
    userRole === 'CRAFTER_ADMIN'
      ? 'users.delete.confirmAdminText'
      : 'users.delete.confirmText'

  return (
    <Form {...methods} bucket={{ type: 'User' }}>
      <DrawerContent>
        <FormHeader profile={profile} />
        <FormContent profile={profile} />
        <FormProfile profile={profile} />
        <HStack gap={unit('30')} paddingBottom={unit('40')}>
          {run(() => {
            if (profile === 'collaborater' && !isAdmin(role)) {
              return (
                <Button
                  {...saveButtonProps}
                  variant="primary"
                  label="actions.save"
                />
              )
            }
            if (profile === 'collaborater' && isAdmin(role)) {
              return (
                <>
                  <ButtonWithConfirm
                    dialogProps={{
                      title: 'users.delete.confirmTitle',
                      children: (
                        <Paragraph
                          whiteSpace={'pre-wrap'}
                          size="md"
                          text={translate(confirmText, {
                            name: fullname,
                          })}
                        />
                      ),
                    }}
                    confirmButtonProps={{
                      isDisabled: false,
                      label: 'users.delete.confirmButton',
                      variant: 'primary',
                      isDangerous: true,
                    }}
                    buttonProps={{
                      label: 'buttons.users.delete',
                      isDisabled: false,
                      variant: 'text',
                      isDangerous: true,
                    }}
                    onConfirm={() => onDelete()}
                  />
                  <Button
                    {...saveButtonProps}
                    variant="primary"
                    label="actions.save"
                  />
                </>
              )
            }
            if (isAgency(role)) {
              return (
                <ButtonWithConfirm
                  dialogProps={{
                    title: 'users.delete.confirmTitle',
                    children: (
                      <Paragraph
                        whiteSpace={'pre-wrap'}
                        size="md"
                        text={translate(confirmText, {
                          name: fullname,
                        })}
                      />
                    ),
                  }}
                  confirmButtonProps={{
                    isDisabled: false,
                    label: 'users.delete.confirmButton',
                    variant: 'primary',
                    isDangerous: true,
                  }}
                  buttonProps={{
                    label: 'buttons.users.delete',
                    isDisabled: false,
                    variant: 'text',
                    isDangerous: true,
                  }}
                  onConfirm={() => onDelete()}
                />
              )
            }
          })}

          {isMyProfile && (
            <Button
              variant="text"
              label="buttons.changePassword"
              to={changePasswordUrl}
              newTab={false}
            />
          )}
        </HStack>
      </DrawerContent>
      <FormUserList profile={profile} />
    </Form>
  )
}
