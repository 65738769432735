import { filterConsole } from '@aubade/core/libs'
import * as Sentry from '@sentry/react'

type SentryConfig = {
  dsn: string
  env: string
  release: string
}

type Props = {
  env: any
  sentryConfig?: SentryConfig
  consoleFilter?: string[]
}

export function appSetup(props: Props) {
  const { env, sentryConfig, consoleFilter = [] } = props

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Setting up app with following env config:', env)
  }

  if (sentryConfig) {
    Sentry.init({
      dsn: sentryConfig.dsn,
      environment: sentryConfig.env,
      release: sentryConfig.release,
      beforeSend(event) {
        const filteredErrors = event?.exception?.values?.filter((error) => {
          return !error?.value?.startsWith('handledException.')
        })
        if (!filteredErrors || !filteredErrors.length) return null
        const filteredEvent = {
          ...event,
          exception: { values: filteredErrors },
        }
        return filteredEvent
      },
    })
  }

  // Use this util to remove warnings when they happen inside a dependency sources
  // Use `console.noFilter()` to print all warnings again, and keep original log location
  ;(window.console as any).noFilter = filterConsole([
    'React will try to recreate this component tree from scratch using the error boundary you provided',
    'Warning: validateDOMNesting(...): <a> cannot appear as a child of <tbody>.', // links on links hack in table lists
    'Warning: validateDOMNesting(...): <object> cannot appear as a child of <tr>.', // links on links hack in table lists
    'wrong listener type: undefined', // from react-leaflet
    'MouseEvent.mozPressure is deprecated. Use PointerEvent.pressure instead.', // from leaflet
    'WebGL warning: texImage: Alpha-premult and y-flip are deprecated for non-DOM-Element uploads', // react-map-gl
    ...consoleFilter,
  ])
}
