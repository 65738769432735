import type { Merge } from '../../core/src/libs'
import type { CraftCompany, UserFunction } from '../src/api'

import type { Society, User, Agency } from './api'
import type { Document } from './api/index'

import type { ExtendedAgency } from './index'

export type ExtendedUser = Merge<
  User.Read,
  {
    jobIds?: string[]
    isNotAvailable: boolean
    acRoles: {
      AC: UserRole | CustomerRole
    }
    registrationTokensBackOffice?: string[]
    userFunctionId?: string
    userFunction?: UserFunction.Read
    agency?: ExtendedAgency
    society?: Society.Read
    craftCompany?: CraftCompany.Read
    societyAgency?: Society.Read
    avatar?: Document.Read[]
  }
>

export type Contact = Merge<
  User.Read,
  Pick<ExtendedUser, 'acRoles'> & {
    ['@id']: `/users/${string}`
    agency?: Agency.Read
    society?: Society.Read
    avatarUrl?: string
    craftCompany?: CraftCompany.Read
    societyAgency?: Society.Read
  }
>

export enum CustomerRole {
  CRAFTER_ADMIN = 'CRAFTER_ADMIN',
  CRAFTER_MANAGER = 'CRAFTER_MANAGER',
  CRAFTER_EMPLOYEE = 'CRAFTER_EMPLOYEE',
}

export enum UserRole {
  'ROLE_SUPER_ADMIN' = 'ROLE_SUPER_ADMIN',
  'ROLE_APP_CRAFTER_ADMIN' = 'ROLE_APP_CRAFTER_ADMIN',
  'ROLE_APP_CRAFTER_COMPANY' = 'ROLE_APP_CRAFTER_COMPANY',
  'ROLE_APP_CRAFTER_STORE' = 'ROLE_APP_CRAFTER_STORE',
}
