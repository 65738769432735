// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconOutilsCouleurs = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49.0595 42.0909C48.6746 42.5058 48.2648 42.8906 47.8649 43.2852L42.3658 48.7523C41.531 49.5767 41.0512 49.5819 40.2262 48.7523C36.947 45.514 33.6727 42.2625 30.4032 38.9979C28.8184 37.4339 27.719 35.53 26.504 33.701C24.5046 30.6628 24.5046 30.6377 21.2202 29.0287V29.0283C19.8659 28.3455 18.6507 27.4168 17.636 26.2898C17.3793 26.0643 17.235 25.7377 17.2405 25.396C17.2465 25.0544 17.402 24.7327 17.666 24.5159C18.3892 23.7731 19.1257 23.0417 19.8757 22.3219L19.8753 22.3223C19.9834 22.2609 20.05 22.1462 20.05 22.0222C20.05 21.8981 19.9834 21.7837 19.8753 21.7224C16.8494 18.7239 13.8251 15.7258 10.8022 12.7274C10.0225 11.9577 8.96769 11.728 7.98789 11.3233C7.41289 11.0985 6.83296 10.8887 6.24826 10.6886C5.55295 10.4536 4.95736 9.99037 4.55879 9.37439C3.32404 7.51543 2.05931 5.67636 0.819653 3.82748C0.279516 3.01808 0.299396 2.84794 1.00935 2.16341C1.40931 1.76358 1.79916 1.35889 2.2141 0.978975V0.979324C2.41076 0.838501 2.62173 0.719281 2.8442 0.624468C3.05098 0.696275 3.25148 0.784816 3.44396 0.889035C5.35866 2.16832 7.28799 3.46778 9.22785 4.74707C9.87399 5.15978 10.3548 5.78551 10.5874 6.51608C10.8974 7.48447 11.2593 8.43537 11.6722 9.36432C11.9577 10.0388 12.3549 10.6607 12.8469 11.2034C15.7212 14.1269 18.6507 16.995 21.5452 19.8986C21.85 20.2033 22.0452 20.2336 22.3451 19.8986C22.9599 19.244 23.6098 18.6294 24.2497 17.9947C25.0594 17.1951 25.6392 17.2303 26.4793 17.9696H26.479C27.8574 19.282 28.9426 20.8708 29.6633 22.6318C30.2844 23.9822 31.305 25.1092 32.5879 25.86C33.9576 26.6945 35.3022 27.5643 36.5872 28.4984V28.4987C37.5179 29.1603 38.3969 29.892 39.2163 30.6874C42.3958 33.8006 45.54 36.9538 48.7144 40.0974C48.8392 40.2222 48.9693 40.3421 49.0843 40.4773L49.0847 40.477C49.312 40.6854 49.4397 40.9814 49.4348 41.2899C49.4299 41.5983 49.2936 41.8898 49.0596 42.0912L49.0595 42.0909Z"
      fill="#004080"
    />
    <path
      d="M28.2694 14.4715C27.8645 14.8012 27.4897 15.1711 27.1846 15.4461C27.9545 16.2758 28.6244 16.9904 29.2842 17.715C30.3941 18.9295 30.9488 20.4733 31.7586 21.8627L31.7589 21.8624C32.0996 22.3894 32.4786 22.8907 32.8936 23.3613C33.1136 23.6311 33.3284 23.621 33.5833 23.3613C34.1632 22.7468 34.7881 22.1769 35.3679 21.5672L35.3683 21.5675C35.5569 21.3267 35.8889 21.2514 36.163 21.3877C37.3988 21.8722 38.7294 22.0653 40.052 21.9524C43.6024 21.7397 46.7774 19.6713 48.4054 16.5104C49.7901 14.0118 50 11.2183 50 8.40492C49.9972 8.26374 49.9739 8.12397 49.9299 7.99011C49.9177 7.85312 49.8281 7.73495 49.6994 7.68616C49.5708 7.63735 49.4254 7.66594 49.3253 7.76005C49.1453 7.87787 48.978 8.01347 48.8252 8.16511C47.6505 9.32937 46.4607 10.479 45.3261 11.6631C44.2064 12.8775 42.564 13.4687 40.9269 13.247C39.2772 13.072 37.9273 12.3975 37.1927 10.8582V10.8585C36.6975 9.87308 36.5235 8.75762 36.6958 7.66832C36.868 6.57902 37.3775 5.57127 38.1526 4.78698C39.4372 3.56275 40.687 2.28837 41.9269 1.02407H41.9266C42.0768 0.805513 42.1898 0.564299 42.2617 0.309138C42.0326 0.176676 41.7846 0.0790782 41.5266 0.0194685C39.4619 -0.0763888 37.3956 0.182253 35.418 0.7839C31.4188 1.96833 29.0944 4.70154 28.1044 8.62445V8.6248C27.7114 10.2886 27.839 12.0328 28.4695 13.622C28.6337 13.9141 28.5469 14.2833 28.2693 14.4715L28.2694 14.4715Z"
      fill="#009EE0"
    />
    <path
      d="M23.3856 33.8705C18.5066 38.7213 13.6278 43.5687 8.74843 48.4124C6.16424 50.9909 2.06 50.3314 0.510414 47.1381C0.10348 46.3342 -0.0656367 45.4307 0.0229341 44.5341C0.111505 43.6376 0.453925 42.7849 1.01014 42.076C1.23889 41.764 1.49483 41.4729 1.77519 41.2063L15.3572 27.7138C15.4771 27.5938 15.602 27.4788 15.7673 27.3241H15.7669C17.1579 29.0844 18.9558 30.4801 21.0058 31.3916C21.8518 31.8141 22.6552 32.316 23.4052 32.8909C23.9203 33.2956 23.8704 33.3907 23.3854 33.8704L23.3856 33.8705Z"
      fill="#009EE0"
    />
  </svg>
)

const Memo = memo(IconOutilsCouleurs)
export default Memo
