import {
  unit,
  Paragraph,
  Chip,
  Button,
  ButtonWithConfirm,
} from '@aubade/core/ui-kit'
import {
  IconOutilsCouleurs,
  IconDelete,
  IconSend,
  IconForbidden,
} from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import { ProfileCard } from '@aubade/domain/components'
import type { ProfileCardProps } from '@aubade/domain/components/ProfileCard'
import { useTranslate } from '@aubade/translation'
import type {
  ExtendedConversation,
  ExtendedUserConversation,
} from '@aubade/types'
import { Box, VStack, HStack } from '@chakra-ui/react'
import { cleanHydraId } from '@nartex/api-platform'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useActions } from '../Actions'
import { NoData } from '../List/index'

function TitleColumn(props: { row: ExtendedConversation }) {
  const { row } = props
  const { users, lastMessage, name, isInformational } = row
  const { id: me } = useConnectedUser()

  const otherUsers = (users ?? []).filter((user) => user.id !== me)

  const profileCardProps = useGetProfileCardProps({ row, me })

  const translate = useTranslate()

  if (otherUsers.length === 0 && isInformational) {
    return (
      <Box width="full" height={unit('60')}>
        <ProfileCard
          initials="E A"
          title={name ?? ''}
          subtitle={translate('messages.subtitle.important')}
          ellipsis
          variant="big"
        />
      </Box>
    )
  }

  if (otherUsers.length === 0) {
    return (
      <VStack
        width="full"
        height={unit('60')}
        justifyContent={'center'}
        alignItems={'flex-start'}
        overflow={'hidden'}
      >
        <Paragraph fontWeight="bold" text={'messages.label.lastMessage'} />
        <Paragraph text={lastMessage?.content ?? ''} />
      </VStack>
    )
  }

  return (
    <Box width="full" height={unit('60')}>
      <ProfileCard {...profileCardProps} />
    </Box>
  )
}

function SecondColumn(props: { row: ExtendedConversation }) {
  const { row } = props
  const { users, isInformational = false } = row

  const { id: me } = useConnectedUser()

  const otherUsers = (users ?? []).filter((user) => user.id !== me)

  if (isInformational) {
    return (
      <VStack
        width="full"
        height={unit('60')}
        justifyContent={'center'}
        alignItems={'flex-start'}
        overflow={'hidden'}
      >
        <Paragraph text={'messages.list.internalMessage'} />
      </VStack>
    )
  }

  if (otherUsers.length === 0)
    return (
      <VStack
        width="full"
        height={unit('60')}
        justifyContent={'center'}
        alignItems={'flex-start'}
        overflow={'hidden'}
      >
        <Paragraph text="conversation.title.lastUser" />
      </VStack>
    )

  if (otherUsers.length > 1) return <NoData />
  return <UserCraftCompany user={otherUsers[0]} />
}

export function useGetColumns() {
  const { id: me } = useConnectedUser()

  const translate = useTranslate()
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props

          return <TitleColumn row={row.original} />
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          return <SecondColumn row={row.original} />
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { users, isInformational } = row.original

          const otherUsers = (users ?? []).filter((user) => user.id !== me)
          if (
            otherUsers.length === 0 ||
            otherUsers.length > 1 ||
            isInformational
          )
            return <NoData />

          return <UserAgencySociety row={row.original} />
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { nbUnreadMessage } = row.original
          if (nbUnreadMessage === 0) return <Box height={unit('60')} />
          const unreadText =
            nbUnreadMessage === 1
              ? 'messages.fields.unread'
              : 'messages.fields.unreadPlural'
          return (
            <VStack
              width="full"
              height={unit('60')}
              justifyContent={'center'}
              alignItems={'flex-end'}
            >
              <Chip
                size="xsmall"
                variant="filled"
                label={translate(unreadText, {
                  count: nbUnreadMessage,
                })}
                tint="error"
                textProps={{ color: 'white.500' }}
              />
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(props) {
          const { row } = props
          const { users } = row.original
          if (!users) return <></>
          return <Actions conversation={row.original} />
        },
      },
    ] satisfies ColumnDef<ExtendedConversation>[]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])
}

type ActionsProps = {
  conversation: ExtendedConversation
}

function Actions(props: ActionsProps) {
  const { conversation } = props
  const { id: me, agency } = useConnectedUser()

  const { id, users, blackList } = conversation
  const { deleteConversation, toggleBlackList, canBlock, isBlocked } =
    useActions(id, users, blackList)
  const [searchParams, setSearchParams] = useSearchParams()
  const translate = useTranslate()

  const otherUsers = useMemo(() => {
    return (users ?? []).filter(
      (user) => cleanHydraId(user.id) !== cleanHydraId(me),
    )
  }, [me, users])

  const canBlockUser = Boolean(
    canBlock &&
      otherUsers.length === 1 &&
      otherUsers[0]?.agencyId &&
      agency &&
      agency.id &&
      cleanHydraId(otherUsers[0].agencyId) === cleanHydraId(agency.id),
  )

  return (
    <HStack
      gap="10px"
      width="full"
      height={unit('60')}
      justifyContent="flex-end"
      alignItems="center"
    >
      <ButtonWithConfirm
        dialogProps={{
          title: 'conversation.confirmTitle',
          children: translate('conversation.confirmText'),
        }}
        confirmButtonProps={{
          isDisabled: false,
          label: 'conversation.confirmButton',
          variant: 'primary',
        }}
        buttonProps={{
          isDisabled: false,
          variant: 'circle',
          leftIcon: IconDelete,
        }}
        onConfirm={() => deleteConversation()}
      />
      {canBlockUser && (
        <Button
          variant="circleList"
          leftIcon={IconForbidden}
          iconColor={isBlocked ? 'error.dark' : undefined}
          onClick={async () => {
            await toggleBlackList()
          }}
        />
      )}
      <Button
        variant="circleList"
        leftIcon={IconSend}
        onClick={() => {
          searchParams.append('id', id)
          setSearchParams(searchParams)
        }}
      />
    </HStack>
  )
}

function UserAgencySociety(props: { row: ExtendedConversation }) {
  const { row } = props
  const { users } = row

  const { id: me } = useConnectedUser()

  const otherUsers = (users ?? []).filter((user) => user.id !== me)
  if (otherUsers.length === 0) return <NoData />
  if (otherUsers.length > 1) return <NoData />
  const { agencyName, societyName, agency, society } = otherUsers[0]
  if (agencyName || agency) {
    return (
      <VStack width="full" height={unit('60')} justifyContent={'center'}>
        <Paragraph
          text={[agencyName ?? society?.name, societyName ?? society?.name]
            .filter(Boolean)
            .join(' - ')}
          ellipsis
        />
      </VStack>
    )
  }
  if (!societyName && !society) return <NoData />

  return (
    <VStack width="full" height={unit('60')} justifyContent={'center'}>
      <Paragraph text={societyName ?? society?.name} ellipsis />
    </VStack>
  )
}

function UserCraftCompany(props: { user: ExtendedUserConversation }) {
  const { user } = props
  const { craftCompany } = user
  if (!craftCompany) return <NoData />

  return (
    <VStack width="full" height={unit('60')} justifyContent={'center'}>
      <Paragraph text={craftCompany.name} ellipsis />
    </VStack>
  )
}

type ProfileProps = {
  row: ExtendedConversation
  role?: string
  me?: string
}

function useGetProfileCardProps(props: ProfileProps): ProfileCardProps {
  const { row, me } = props
  const { users, isInformational = false, name } = row
  const otherUsers = (users ?? []).filter((user) => user.id !== me)

  const translate = useTranslate()

  if (otherUsers.length === 0) return { title: '' }

  if (isInformational) {
    const { avatar } = otherUsers[0]
    return {
      title: name ?? '',
      subtitle: translate('messages.subtitle.important'),
      picture: avatar,
      ellipsis: true,
      variant: 'big',
    }
  }

  if (otherUsers.length === 1) {
    const {
      avatar,
      firstname,
      lastname,
      isAvailable,
      craftCompany,
      userFunction,
      acRoles,
    } = otherUsers[0]

    const fullName = [firstname, lastname].filter(Boolean).join(' ')

    return {
      title: fullName,
      isAvailable,
      picture: avatar,
      url: '',
      subtitle: userFunction?.name ?? acRoles.AC ?? '',
      variant: 'big',
      icon: craftCompany ? IconOutilsCouleurs : undefined,
    }
  }
  if (otherUsers.length > 1) {
    const userNames = users
      .map((otherUser) =>
        [otherUser.firstname, otherUser.lastname].filter(Boolean).join(' '),
      )
      .join(', ')
    return {
      multiple: users.length,
      title: name ?? translate('messages.list.group'),
      subtitle: userNames,
      ellipsis: true,
      variant: 'big',
    }
  }
  return { title: '' }
}
