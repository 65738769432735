import type {
  DataProvider,
  ResourceDataProvider,
  CrudSort,
  UpdateResponse,
} from '@aubade/core/adapters'
import type { ToolsForm } from '@aubade/types'
import type { Tool } from '@aubade/types/api'
import {
  type SearchResponse,
  encodePagination,
  encodeFilters,
} from '@nartex/typesense'
import { groupBy } from 'remeda'

export function ToolsProxy(dataProvider: DataProvider) {
  return {
    // @ts-expect-error any
    async getOne(params) {
      const data = await dataProvider.getOne<Tool.Read>(params)

      const { document } = data.data
      let fullDocument
      if (document) {
        const { data: fullDocumentData } = await dataProvider.getOne({
          ...params,
          resource: 'documents',
          id: document.id!,
        })
        fullDocument = fullDocumentData
      }

      return {
        ...data,
        data: {
          ...data.data,
          document: { ...document, ...fullDocument },
          template: document ? 'logo' : 'text',
        } as ToolsForm,
      }
    },
    async create(params) {
      const variables = params.variables as unknown as ToolsForm
      const { template, url, lineOne, lineTwo } = variables
      let name: string = ''
      if (template === 'logo') {
        name = url
      } else {
        name = `${lineOne} ${lineTwo}`
      }

      return dataProvider.create({
        ...params,
        // @ts-expect-error any
        resource: 'tools/create',
        variables: {
          ...params.variables,
          name,
        },
      })
    },
    async update(params) {
      const { id } = params
      const { variables } = params
      const { template, url, lineOne, lineTwo } = variables

      const name = template === 'logo' ? (url ?? '') : `${lineOne} ${lineTwo}`

      return dataProvider.custom!<ToolsForm, Partial<ToolsForm>>({
        ...params,
        method: 'put',
        url: `tools/${id}/update`,
        payload: {
          ...params.variables,
          name,
        },
      }) as Promise<UpdateResponse<ToolsForm>>
    },
    async getList(params) {
      const { filters, hasPagination, pagination, sort, metaData } = params
      const { search, tsFilters } = groupBy(filters ?? [], (filter) => {
        if ('q' in filter) return 'search'
        if ('field' in filter) {
          return 'tsFilters'
        }
      })
      const sortBy = encodeSortBy(sort).sort_by
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        url: `tools/ts/search`,
        metaData,
        payload: {
          searches: [
            {
              collection: 'tools',
              query_by: 'name',
              ...encodePagination(hasPagination ? pagination : {}),
              ...encodeFilters([...(search ?? []), ...(tsFilters ?? [])]),
              sort_by: sortBy,
            },
          ],
        },
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
  } satisfies ResourceDataProvider<ToolsForm>
}

function encodeSortBy<T>(sort: CrudSort<T>[] | undefined) {
  return {
    sort_by:
      sort === undefined
        ? undefined
        : sort
            .map((item) => {
              const { field, order } = item
              return `${field}:${order}`
            })
            .join(','),
  }
}
