import type { DataProvider } from '@aubade/core/adapters'
import type { Publication } from '@aubade/types/api'

type PostRead = {
  data: {
    'hydra:member': Publication.Read[]
    'hydra:totalItems': number
  }
}

export function FeedProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    ...dataProvider,
    // @ts-expect-error any
    async getList(params) {
      const { filters, metaData, pagination } = params

      const data = (await dataProvider.custom!({
        method: 'post',
        url: `posts/published?page=${pagination?.current ?? 1}&itemsPerPage=${
          pagination?.pageSize
        }`,
        payload: filters,
        headers: metaData?.headers,
      })) as PostRead
      return {
        ...data,
        data: data.data['hydra:member'],
        total: data.data['hydra:totalItems'],
      }
    },
  }
}
