import { useQueryParamsState } from '@aubade/core/libs'
import { Form, useForm, Paragraph } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isAdmin, isSociety } from '@aubade/domain/components'
import type { PublicationForm } from '@aubade/types'
import { PublicationLinkEnum } from '@aubade/types'
import { VStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'
import { useMemo, useState, useEffect } from 'react'
import { config } from 'src/config'
import { StringParam } from 'use-query-params'
import { v4 as uuid } from 'uuid'

import { FormContent } from './form/FormContent'

export function CreateStandAlone() {
  return <FormCreate />
}

export function FormCreate() {
  const [tokenParam] = useQueryParamsState({ token: StringParam })
  if (!tokenParam || tokenParam.token === undefined) {
    return <>Vous devez être connecté pour accéder à cet interface</>
  }

  sessionStorage.setItem('jwt', tokenParam.token)

  return <FormImpl />
}

function FormImpl() {
  const [count, setcount] = useState(0)
  const newId = useMemo(() => {
    return uuid()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const { id: me, society, role } = useConnectedUser()

  const defaultSocieties = useMemo(() => {
    if (!society || !isSociety(role) || !society.id) return []
    return [toHydraId('societies', society.id)]
  }, [role, society])

  const { features } = config

  const defaultValues = useMemo(() => {
    return {
      newId,
      linkType: PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK'],
      author: toHydraId('users', me),
      isDraft: false,
      isNational: isAdmin(role),
      isAgencies: isSociety(role),
      publishedAt: new Date() as any,
      allCategories: true,
      isProAubade: false,
      societies: defaultSocieties,
      hasEaCard: features.eACard ? false : undefined,
    } as any
  }, [defaultSocieties, features.eACard, me, newId, role])

  const { saveButtonProps, ...methods } = useForm<PublicationForm>({
    mode: 'create',
    resource: 'publications',
    redirect() {
      return false
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues,
    },
    mutationOptions: {
      async onSuccess() {
        setcount(count + 1)
      },
      onError() {
        alert("une erreur s'est produite de notre coté")
      },
    },
  })

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      newId,
      documents: [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  const memoForm = useMemo(() => {
    return (
      <FormContent newPulicationId={newId} standAlone {...saveButtonProps} />
    )
  }, [newId, saveButtonProps])

  return (
    <VStack
      padding={['20px', '20px', '10px']}
      paddingBottom={['40px', '40px', '10px']}
      gap={'20px'}
      alignItems={'flex-start'}
      width="full"
    >
      <Paragraph
        fontWeight="bold"
        size={['sm', 'sm', 'lg']}
        color="black.base"
        text="publications.title.create"
      />
      <Form {...methods} bucket={{ type: 'Publication' }}>
        {memoForm}
      </Form>
    </VStack>
  )
}
