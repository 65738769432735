import { Button, Paragraph } from '@aubade/core/ui-kit'
import type { ApiToken } from '@aubade/domain/components'
import { OidcClient } from '@axa-fr/oidc-client'
import type { OidcConfiguration } from '@axa-fr/react-oidc'
import { OidcProvider, TokenRenewMode, useOidc } from '@axa-fr/react-oidc'
import { Center, Spinner, VStack } from '@chakra-ui/react'
import { run } from '@nartex/stdlib'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useState, type PropsWithChildren } from 'react'
import { useEnv } from 'src/adapters/Env'

import type { EventNames } from './authTypes'

type ErrorState = 'expired' | 'unhautorized'

export function Auth(props: PropsWithChildren<object>) {
  const env = useEnv()
  const { KEYCLOAK_CONFIG, BASE_PATH } = env
  const authority = `${KEYCLOAK_CONFIG.url}/realms/${KEYCLOAK_CONFIG.realm}`
  const oidcConfig: OidcConfiguration = {
    client_id: 'app-crafter-web',
    // service_worker_relative_url: '/bo/OidcServiceWorker.js',
    redirect_uri:
      window.location.origin + `${BASE_PATH}/authentication/callback`,

    silent_redirect_uri:
      window.location.origin + `${BASE_PATH}/authentication/silent-callback`,
    scope: 'openid',
    service_worker_only: false,
    refresh_time_before_tokens_expiration_in_second: 60, // 1 minutes
    service_worker_convert_all_requests_to_cors: true,
    token_renew_mode: TokenRenewMode.access_token_invalid,
    authority,
  }

  const [error, setError] = useState<ErrorState | undefined>(undefined)

  async function onEvent(_configuration: string, name: string, data: any) {
    const apiToken = sessionStorage.getItem('jwt')
    const keycloakToken = getKeycloakToken()

    if (
      (name as EventNames) === 'refreshTokensAsync_begin' &&
      data.status === 'TOKENS_INVALID'
    ) {
      setError('expired')
    }

    if ((name as EventNames) === 'token_aquired') {
      await tryConnect({
        keycloakToken: data.accessToken,
        apiUrl: env.API_URL,
        reload: true,
        setError,
      })
    }
    if (
      (name as EventNames) === 'refreshTokensAsync_end' &&
      data.success === true
    ) {
      await tryConnect({
        keycloakToken: keycloakToken!,
        apiUrl: env.API_URL,
        setError,
      })
    }
    if (
      (name as EventNames) === 'tryKeepExistingSessionAsync_end' &&
      data.success === true
    ) {
      await tryConnect({
        keycloakToken: keycloakToken!,
        apiUrl: env.API_URL,
        setError,
      })
    }
    if (
      (name as EventNames) === 'tryKeepExistingSessionAsync_end' &&
      data.success === false
    ) {
      sessionStorage.removeItem('oidc.default')
      sessionStorage.removeItem('jwt')
    }
    if (
      (name as EventNames) === 'token_timer' &&
      isInvalidToken(keycloakToken)
    ) {
      const oidc = OidcClient.get('default')
      await oidc.loginAsync()
    }
    if ((name as EventNames) === 'token_timer' && isInvalidToken(apiToken)) {
      await tryConnect({
        keycloakToken: keycloakToken!,
        apiUrl: env.API_URL,
        reload: true,
        setError,
      })
    }
  }
  if (error) {
    return <OidcErrors error={error} />
  }

  return (
    <>
      <OidcProvider
        configuration={oidcConfig}
        loadingComponent={KeycloakSpinner}
        authenticatingComponent={KeycloakSpinner}
        callbackSuccessComponent={KeycloakSpinner}
        onEvent={onEvent}
      >
        {props.children}
      </OidcProvider>
    </>
  )
}

type LoginProps = {
  keycloakToken: string
  apiUrl: string
  setError: any
  reload?: boolean
}

async function tryConnect(props: LoginProps) {
  const { keycloakToken, apiUrl, reload = false, setError } = props
  try {
    const res = await axios.post(`${apiUrl}/login`, {
      token_keycloack: keycloakToken,
    })
    if (res && res.data.error) {
      throw Error('Connexion impossible')
    }
    if (res && res.status === 200 && !res.data.error) {
      sessionStorage.setItem('jwt', res?.data.token)
      if (reload) {
        window.location.reload()
      }
    }
  } catch (error: any) {
    if (
      error.response.data.status === 403 &&
      error.response.data.detail.includes('no ac roles')
    ) {
      setError('unhautorized')
    }
  }
}

function KeycloakSpinner() {
  return (
    <Center width="100vw" height="100vh">
      <Spinner />
    </Center>
  )
}

export function isInvalidToken(token?: string | null) {
  if (!token || token === null) {
    return true
  }
  const tok = jwt_decode<ApiToken>(token)

  if (tok.exp) {
    const now = new Date()
    const exp = new Date(tok.exp * 1000)
    if (exp < now) {
      return true
    }
  }

  return false
}

function getKeycloakToken() {
  const oidcDefault = sessionStorage.getItem('oidc.default')

  if (oidcDefault === null) {
    return undefined
  }
  return JSON.parse(sessionStorage.getItem('oidc.default')!).tokens?.accessToken
}

type OidcErrorProps = {
  error: ErrorState
}

function OidcErrors(props: OidcErrorProps) {
  const { error } = props
  const { logout, login } = useOidc()
  const env = useEnv()
  const { BASE_PATH } = env
  const queryClient = useQueryClient()

  return (
    <VStack
      width="full"
      height="30vh"
      justifyContent={'center'}
      alignItems={'center'}
      gap={4}
    >
      <Paragraph size={'lg'} text={`oicd.error.${error}`} />
      {run(() => {
        if (error === 'unhautorized') {
          return (
            <Button
              label="menu.logout"
              variant="primary"
              onClick={async () => {
                queryClient.clear()
                sessionStorage.removeItem('jwt')
                sessionStorage.removeItem('agency')
                localStorage.clear()
                await logout(`${BASE_PATH}/fil`)
              }}
            />
          )
        }
        if (error === 'expired') {
          return (
            <Button
              label="menu.login"
              variant="primary"
              onClick={async () => {
                queryClient.clear()
                sessionStorage.removeItem('jwt')
                sessionStorage.removeItem('agency')
                localStorage.clear()
                await login(`${BASE_PATH}/fil`)
              }}
            />
          )
        }
      })}
    </VStack>
  )
}
