import type { Society, Agency } from '@aubade/types/api'
import type { Typesense } from '@aubade/types/typesense'
import type { DataProvider } from '@nartex/data-provider/react'
import type { SearchResponse } from '@nartex/typesense'
import { prop, sortBy } from 'remeda'

import { encodePayloadApiToTypesense } from '../index'

export function AgenciesProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-expect-error any
    async getOne(params) {
      const agency = await dataProvider.getOne<Agency.Read>(params)
      const { society } = agency.data
      const { data: fullSociety } = await dataProvider.getOne<Society.Read>({
        ...params,
        resource: 'societies',
        id: society,
      })
      const data = { ...agency.data, society: fullSociety }
      return {
        ...agency,
        data,
      }
    },
    // @ts-expect-error any
    async getList(params) {
      const { metaData } = params

      const payload = encodePayloadApiToTypesense(params, 'agencies', 'name')
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<Typesense.Agencies>]
        },
        any
      >({
        method: 'post',
        url: `agencies/ts/search`,
        metaData,
        payload,
      })

      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      const sortedDatas = sortBy(data as any, prop('name'))

      return {
        data: sortedDatas,
        total: data.length,
        raw: { typesense: result },
      }
    },
  }
}
