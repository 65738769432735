import type {
  UseFiltersSearchParamsReturn,
  GetRowProps,
} from '@aubade/core/ui-kit'
import {
  Surface,
  cssSize,
  Button,
  ResourceList,
  Paragraph,
  Chip,
  UnixDateFormat,
  ButtonWithConfirm,
  useFiltersSearchParams,
  unit,
} from '@aubade/core/ui-kit'
import {
  IconPlus,
  IconDot,
  IconRecherche,
  IconPen,
  IconDelete,
} from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import { useTranslate } from '@aubade/translation'
import type { TemporaryTypesensenotification } from '@aubade/types/index'
import type { Typesense } from '@aubade/types/typesense'
import { VStack, HStack } from '@chakra-ui/react'
import type { ColumnDef } from '@tanstack/react-table'
import { isBefore } from 'date-fns'
import { forwardRef, memo, useMemo, useCallback } from 'react'
import { useUrls } from 'src/App'
import { CalendarFilter } from 'src/components/Filters'
import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { useNotificationRights } from '../../adapters/rights'
import { ListHeader } from '../../components/ListHeader'
import { MultiList } from '../../components/MultiList'
import { Padding } from '../../components/ResourcePageLayout'
import { usePublicationFilters } from '../Publications/filters'
import { FilterBar } from '../Publications/filters/FilterForm'
import type { PublicationFilterType } from '../Publications/filters/types'

import { useActions } from './actions'
import { NotificationsEdit } from './CreateEdit'

const templateColumns = '400px 140px 1fr'
const resource = 'notifications'
const scope = 'notifications'

export function NotificationsList() {
  const listFilters = usePublicationFilters('notifications')
  return (
    <ResourcePageLayout>
      <NotificationList listFilters={listFilters} />
      <LeftPanel listFilters={listFilters} />
      <NotificationsEdit />
    </ResourcePageLayout>
  )
}

type ListFilterProps = {
  listFilters: UseFiltersSearchParamsReturn<
    PublicationFilterType,
    TemporaryTypesensenotification
  >
}

function NotificationList(props: ListFilterProps) {
  const { listFilters } = props
  const { filtersStore } = listFilters

  const columns = useGetColumns()
  const urls = useUrls()
  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
      paddingX={unit('10')}
      paddingY={unit('40')}
    >
      <ListHeader
        scope={scope}
        mainAction={
          <Button
            variant="primary"
            label={'actions.newNotification'}
            leftIcon={IconPlus}
            to={urls.aubade().notificationsCreate()}
          />
        }
        filters={
          <FilterBar
            onChange={filtersStore.setState}
            filterState={filtersStore.state}
            resource={resource}
          />
        }
      />
      <MultiList<Typesense.Notifications>
        templateColumns={templateColumns}
        listFilters={listFilters}
        columns={columns}
        resource={resource}
        getRowProps={useCallback<GetRowProps<Typesense.Notifications>>(
          (row) => {
            return {
              to: urls.aubade().notificationsEdit(row.id),
            }
          },
          [urls],
        )}
      />
    </Surface>
  )
}

const LeftPanel = memo(
  forwardRef<HTMLDivElement, ListFilterProps>(function (props, ref) {
    const { listFilters } = props
    const { filtersStore, crudFilters: publicationsCrudFilters } = listFilters
    const { id: me } = useConnectedUser()
    const { crudFilters } = useFiltersSearchParams({
      scope: 'draft',
      defaultFilters: { isDraft: true, 'author.id': me },
    })
    return (
      <VStack
        ref={ref}
        minWidth={unit('384')}
        maxWidth={unit('384')}
        gap={Padding}
        height="full"
        alignItems={'flex-start'}
        min-width="full"
      >
        <Surface padding={0} minHeight="370px" overflowY="hidden">
          <CalendarFilter
            onChange={filtersStore.setState}
            filterState={filtersStore.state}
            crudFilters={publicationsCrudFilters}
            resource="notifications"
          />
        </Surface>
        <Surface height="full" paddingY={10} paddingX={5} overflowY="auto">
          <ResourceList<Typesense.Notifications>
            filters={crudFilters}
            sort={[{ field: 'sort_value', order: 'desc' }]}
            scope="draft"
            variant="draft"
            resource={resource}
            title="notifications.tabletitle.draft"
            tableProps={{
              templateColumns: '200px 100px',

              columns: useMemo(
                () =>
                  [
                    {
                      header: '',
                      accessorKey: 'title',
                      cell(cellProps) {
                        const { row } = cellProps
                        const { title } = row.original
                        return (
                          <HStack width={'full'} height="full">
                            <Paragraph
                              text={title}
                              fontWeight="bold"
                              size="sm"
                              ellipsis
                            />
                          </HStack>
                        )
                      },
                    },

                    {
                      header: '',
                      accessorKey: 'id',
                      cell(cellProps) {
                        const { row } = cellProps

                        return (
                          <HStack
                            width={'full'}
                            height="full"
                            justifyContent={'flex-end'}
                          >
                            <Actions notification={row.original} />
                          </HStack>
                        )
                      },
                    },
                  ] satisfies ColumnDef<TemporaryTypesensenotification>[],
                [],
              ),
            }}
          />
        </Surface>
      </VStack>
    )
  }),
)

function useGetColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'title',
        cell(props) {
          const { row } = props
          const { title, publishedAt } = row.original
          return <NotificationExcerpt title={title} publishedAt={publishedAt} />
        },
      },
      {
        header: '',
        accessorKey: 'targetAgency',
        cell(props) {
          const { row } = props
          const { targetAgencyNames, targetSocietyNames, targets } =
            row.original
          return (
            <VStack
              gap={cssSize('5px')}
              alignItems="flex-start"
              justifyContent={'center'}
              height="full"
            >
              {targets?.includes('national') ? (
                <Paragraph text={'publications.fields.nationalTarget'} />
              ) : (
                <>
                  <Paragraph text={targetSocietyNames?.join(', ')} ellipsis />
                  <Paragraph text={targetAgencyNames?.join(', ')} ellipsis />
                </>
              )}
            </VStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'targetSociety',
        cell(props) {
          const { row } = props

          return <Actions notification={row.original} />
        },
      },
    ] satisfies ColumnDef<Typesense.Notifications>[]
  }, [])
}

type NotificationExcerptProps = {
  title?: string
  publishedAt?: number
}

function NotificationExcerpt(props: NotificationExcerptProps) {
  const { title, publishedAt } = props
  const isPublished = isBefore(new Date(publishedAt! * 1000), new Date())
  return (
    <VStack
      alignItems="flex-start"
      gap={cssSize('5px')}
      overflow="hidden"
      height="full"
      justifyContent="center"
    >
      <Paragraph text={title} fontWeight="bold" size="sm" ellipsis />
      <HStack gap={cssSize('7px')}>
        <PublishedChip isPublished={isPublished} />
        <Paragraph size="sm">
          <UnixDateFormat value={publishedAt} />
        </Paragraph>
      </HStack>
    </VStack>
  )
}

function PublishedChip(props: { isPublished: boolean }) {
  const { isPublished } = props

  const status = getPublicationStatus(isPublished)

  return (
    <Chip
      size="xsmall"
      label={status.label}
      iconLeftProps={{ as: IconDot, color: status.mainColor }}
      textProps={{ color: status.mainColor }}
    />
  )
}

function getPublicationStatus(isPublished: boolean) {
  const mainColor = isPublished ? 'darkGrey.500' : 'blue.500'

  const label = isPublished
    ? 'notification.status.published'
    : 'notification.status.scheduled'
  return {
    mainColor,
    label,
  }
}

type PropsActions = {
  notification: Typesense.Notifications
}

function Actions(props: PropsActions) {
  const { notification } = props
  const { isDraft, id, 'author.id': authorId, publishedAt } = notification

  const { onDelete } = useActions(authorId)

  const publishedDate = publishedAt ? new Date(publishedAt * 1000) : undefined

  const canDelete = useNotificationRights().useCanDeleteNotif(authorId)

  const canDeleteNotification = Boolean(
    (canDelete && isDraft) ||
      (canDelete &&
        publishedDate &&
        new Date(publishedDate).getTime() >= Date.now()),
  )

  const urls = useUrls()

  const translate = useTranslate()

  if (isDraft) {
    return (
      <HStack>
        <Button
          variant="circle"
          leftIcon={IconPen}
          to={urls.aubade().notificationsEdit(id)}
        />
        <ButtonWithConfirm
          dialogProps={{
            title: 'notifications.confirmTitle',
            children: translate('notifications.confirmText'),
          }}
          confirmButtonProps={{
            isDisabled: false,
            label: 'notifications.confirmButton',
            variant: 'primary',
          }}
          buttonProps={{
            isDisabled: false,
            leftIcon: IconDelete,
            variant: 'circle',
          }}
          onConfirm={() => onDelete(id)}
        />
      </HStack>
    )
  }
  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      justifyContent="flex-end"
      alignItems="center"
    >
      {canDeleteNotification && (
        <ButtonWithConfirm
          dialogProps={{
            title: 'notifications.confirmTitle',
            children: translate('notifications.confirmText'),
          }}
          confirmButtonProps={{
            isDisabled: false,
            label: 'notifications.confirmButton',
            variant: 'primary',
          }}
          buttonProps={{
            isDisabled: false,
            leftIcon: IconDelete,
            variant: 'circle',
          }}
          onConfirm={() => onDelete(id)}
        />
      )}

      <Button
        variant="circle"
        leftIcon={IconRecherche}
        to={urls.aubade().notificationsEdit(id)}
      />
    </HStack>
  )
}
