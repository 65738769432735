import { HStack } from '@chakra-ui/react'
import { AgenciesActiveFilter } from 'src/components/ActiveFilters'

import { ActiveProfileConversationFilters } from '../../../components/ActiveFilters/index'

type Props = {
  onChange: () => void
}
export function ActiveFilters(props: Props) {
  return (
    <HStack width="full" wrap="wrap" gap={2}>
      <AgenciesActiveFilter {...props} variant="negative" />
      <ActiveProfileConversationFilters {...props} variant="negative" />
    </HStack>
  )
}
