import { HTTPClient, type BaseRecord } from '@aubade/core/adapters'
import { useQueryMetaData, DataProvider } from '@nartex/api-platform'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { DeepPartial } from 'react-hook-form'

import { antiCorruptionLayerProxy } from '../adapters/antiCorruptionLayerProxy'
import { useEnv } from '../adapters/Env'

export function useInfiniteList<T extends BaseRecord, U>(
  resource: string,
  filters?: DeepPartial<U>,
  pageSize: number = 20,
) {
  const queryMetaData = useQueryMetaData()

  const env = useEnv()

  const httpClient = HTTPClient({
    baseURL: env.API_URL,
    getAuthToken() {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const token = urlParams.get('token')
      const storedToken = sessionStorage.getItem('jwt')
      if (token && token !== null) return token
      return storedToken
    },
  })

  const apiDataProvider = DataProvider({
    httpClient,
    apiUrl: env.API_URL,
  })
  const withAntiCorruptionLayer = antiCorruptionLayerProxy(apiDataProvider)

  return useInfiniteQuery({
    queryKey: ['list', { resource, filters, pageSize, queryMetaData }],
    queryFn: async (queryVariables) => {
      const { pageParam = 1 } = queryVariables

      const listResponse = await withAntiCorruptionLayer.getList<T>({
        resource: resource as any,
        pagination: {
          pageSize,
          current: pageParam === null ? 1 : pageParam,
        },
        filters: filters as any,
        metaData: queryMetaData as any,
      })
      return {
        ...listResponse,
        currentPage: pageParam,
      }
    },

    enabled: true,
  })
}
