import { SmartGrid } from '@aubade/core/ui-kit'
import { Select, Icon } from '@chakra-ui/react'
import { format, isValid } from 'date-fns'

import { IconArrowBottom } from '../../../../../design/src/graphics'
import { useTranslate } from '../../../../../translation/src'
import type { Transformer } from '../../Inputs'
import type { RHFInputProps, BaseInputProps } from '../controller'
import { useRHFInput, useBaseInput } from '../controller'
import { getSelectOptions } from '../getSelectOptions'
import { BaseInputGroup, TextInput } from '../TextInput/TextInput'

import { DateInput } from './DateInput'

export function RHFDateInput<T>(
  props: RHFInputProps<T, Date> & {
    variant?: 'rounded'
    min?: Date
    max?: Date
  },
) {
  const { variant, min, max, ...rest } = props
  const innerProps = useRHFInput<T, Date, Date>(rest)

  return <DateInput {...innerProps} variant={variant} min={min} max={max} />
}

export function RHFDateTimeInput<T>(
  props: RHFInputProps<T, Date> & { min?: boolean; list?: boolean },
) {
  const { min, list } = props
  const innerProps = useRHFInput<T, Date, Date>(props)

  return <InputDateTime {...innerProps} min={min} list={list} />
}

export function dateTimeTransformer(
  min: boolean,
): Transformer<Date | null | undefined, string | undefined> {
  return {
    read(value) {
      if (!value) return ''
      if (min) {
        if (new Date(value).getTime() < new Date().getTime()) {
          const now = new Date()
          if (now.getHours() < 8) {
            now.setHours(8, 0, 0, 0)
            return format(now, "yyyy-MM-dd'T'HH:mm")
          }
          if (now.getHours() >= 19) {
            const nextDay = now.getDate() + 1
            now.setDate(nextDay)
            now.setHours(8, 0, 0, 0)
            return format(now, "yyyy-MM-dd'T'HH:mm")
          }

          const nextHour = now.getHours() + 1
          if (now.getMinutes() < 30) {
            now.setMinutes(30, 0, 0)
            return format(now, "yyyy-MM-dd'T'HH:mm")
          } else {
            now.setHours(nextHour, 0, 0, 0)
            return format(now, "yyyy-MM-dd'T'HH:mm")
          }
        }
      }
      return format(new Date(value), "yyyy-MM-dd'T'HH:mm")
    },
    write(value) {
      if (!value) return undefined
      const date = new Date(value)

      if (isValid(date)) {
        if (min && date.getTime() < new Date().getTime()) {
          const now = new Date()
          if (now.getHours() < 8) {
            now.setHours(8, 0, 0, 0)
            return now
          }
          if (now.getHours() >= 19) {
            const nextDay = now.getDate() + 1
            now.setDate(nextDay)
            now.setHours(8, 0, 0, 0)
            return now
          }

          const nextHour = now.getHours() + 1
          if (now.getMinutes() < 30) {
            now.setMinutes(30, 0, 0)
            return now
          } else {
            now.setHours(nextHour, 0, 0, 0)
            return now
          }
        }
        return date
      } else {
        return undefined
      }
    },
  }
}

const timeOptions: Record<string, string> = {
  '08:00': '8:00',
  '08:30': '8:30',
  '09:00': '9:00',
  '09:30': '9:30',
  '10:00': '10:00',
  '10:30': '10:30',
  '11:00': '11:00',
  '11:30': '11:30',
  '12:00': '12:00',
  '12:30': '12:30',
  '13:00': '13:00',
  '13:30': '13:30',
  '14:00': '14:00',
  '14:30': '14:30',
  '15:00': '15:00',
  '15:30': '15:30',
  '16:00': '16:00',
  '16:30': '16:30',
  '17:00': '17:00',
  '17:30': '17:30',
  '18:00': '18:00',
  '18:30': '18:30',
  '19:00': '19:00',
}

export function InputDateTime(
  props: BaseInputProps<Date> & { min?: boolean; list?: boolean },
) {
  const { label, min = false, list = false, ...rest } = props
  const { id, field, wrap } = useBaseInput<Date, Date, string | undefined>(
    rest,
    {
      baseTransformer: dateTimeTransformer(min),
    },
  )

  const { value } = field
  const date = value?.split('T')[0]
  const time = value?.split('T')[1]

  const options = getSelectOptions(timeOptions)

  const translate = useTranslate()

  return wrap(
    <BaseInputGroup label={label} id={id}>
      <SmartGrid columns={2}>
        <TextInput
          type="date"
          disabled={field.disabled || field.readOnly}
          value={String(date)}
          onChange={(val) => {
            const newDate = [val, time].filter(Boolean).join('T')
            field.onChange(newDate)
          }}
        />
        {list ? (
          <Select
            {...field}
            disabled={field.readOnly || field.disabled}
            icon={<Icon as={IconArrowBottom} color="darkGrey.500" />}
            iconSize="15px"
            fontSize="sm"
            value={String(time)}
            onChange={(evt) => {
              const newDate = [date, evt.currentTarget.value]
                .filter(Boolean)
                .join('T')
              field.onChange(newDate)
            }}
          >
            {options.map(([optionValue, optionLabel]) => {
              return (
                <option key={optionValue} value={optionValue}>
                  {translate(optionLabel)}
                </option>
              )
            })}
          </Select>
        ) : (
          <TextInput
            type="time"
            disabled={field.disabled || field.readOnly}
            value={String(time)}
            onChange={(timeValue) => {
              const newDate = [date, timeValue].filter(Boolean).join('T')
              field.onChange(newDate)
            }}
          />
        )}
      </SmartGrid>
    </BaseInputGroup>,
  )
}
