import { type LogicalFilter } from '@aubade/core/adapters'
import { toCrudFilters, useFiltersSearchParams } from '@aubade/core/ui-kit'
import { cleanHydraId } from '@nartex/api-platform'
import { differenceInDays, endOfDay, startOfDay } from 'date-fns'

import type { TemporaryStatSummary } from '../types'

export type StatisticsFiltersType = {
  agencies?: string[]
  societies?: string[]
  dateOptions?: 'day' | 'month'
  date?: {
    startDate?: Date
    endDate?: Date
  }
  displayType?: 'day' | 'month' | 'society' | 'agency'
  displayMessageType?: 'associates' | 'customers' | 'all'
  isNational?: boolean
}

export function useStatisticsFilters(
  defaultFilters: Partial<StatisticsFiltersType>,
) {
  const defaultDisplayType = getDefaultDisplayType(defaultFilters.date)

  return useFiltersSearchParams<StatisticsFiltersType, TemporaryStatSummary>({
    scope: 'statistics',
    defaultFilters: {
      ...defaultFilters,
      displayType: defaultDisplayType,
    },
    toCrudFilters(filtersState) {
      const {
        agencies,
        societies,
        date,
        displayMessageType,
        displayType,
        ...defaultParse
      } = filtersState

      let filters: LogicalFilter<StatisticsFiltersType>[] = []
      if (date && date.startDate && date.endDate) {
        filters = [...filters, ...dateFiltertoCrud(date)]
      }

      const filteredAgencies = agencies?.filter(
        (agency) => agency !== undefined,
      )
      const filteredSocieties = societies?.filter(
        (society) => society !== undefined,
      )

      return [
        ...toCrudFilters(defaultParse),
        ...filters,
        filteredAgencies && targetsFilterToCrud(filteredAgencies, 'agencies'),
        displayType && displayTypeFilterToCrud(displayType, date),
        filteredSocieties &&
          targetsFilterToCrud(filteredSocieties, 'societies'),
        displayMessageType &&
          displayMessageTypeFilterToCrud(displayMessageType),
      ]
    },
  })
}

function targetsFilterToCrud(
  agencies: string[],
  key: 'agencies' | 'societies',
): LogicalFilter<StatisticsFiltersType> {
  const agenciesArray = agencies.map(cleanHydraId)
  if (agenciesArray.length === 0) {
    return {
      field: key,
      operator: 'eq',
      value: undefined,
    }
  }
  return {
    field: key,
    operator: 'eq',
    value: agenciesArray,
  }
}

function dateFiltertoCrud(
  date: StatisticsFiltersType['date'],
): LogicalFilter<StatisticsFiltersType>[] {
  return [
    {
      field: 'startDate',
      operator: 'eq',
      value: startOfDay(new Date(date!.startDate!)).getTime() / 1000,
    },
    {
      field: 'endDate',
      operator: 'eq',
      value: endOfDay(new Date(date!.endDate!)).getTime() / 1000,
    },
  ]
}

function displayTypeFilterToCrud(
  displayType: StatisticsFiltersType['displayType'],
  date: StatisticsFiltersType['date'],
): LogicalFilter<StatisticsFiltersType> {
  if (displayType === 'month' || displayType === 'day') {
    if (
      differenceInDays(new Date(date!.endDate!), new Date(date!.startDate!)) >
      30
    ) {
      return {
        field: 'displayType',
        operator: 'eq',
        value: 'month',
      }
    } else {
      return {
        field: 'displayType',
        operator: 'eq',
        value: 'day',
      }
    }
  } else {
    return {
      field: 'displayType',
      operator: 'eq',
      value: displayType,
    }
  }
}

function displayMessageTypeFilterToCrud(
  displayMessageType: StatisticsFiltersType['displayMessageType'],
): LogicalFilter<StatisticsFiltersType> {
  if (displayMessageType === 'all')
    return {
      field: 'displayMessageType',
      operator: 'eq',
      value: undefined,
    }
  return {
    field: 'displayMessageType',
    operator: 'eq',
    value: displayMessageType,
  }
}

export function getDefaultDisplayType(date: StatisticsFiltersType['date']) {
  const displayType =
    differenceInDays(new Date(date!.endDate!), new Date(date!.startDate!)) > 30
      ? 'month'
      : 'day'

  return displayType
}
