import { useToastMessage } from '@aubade/core/ui-kit'
import type { Api } from '@aubade/types/api'
import { cleanHydraId, useUpdate } from '@nartex/data-provider/react'
import type { DataProvider, HttpError } from '@pankod/refine-core'
import * as Sentry from '@sentry/browser'
import { useQueryClient } from '@tanstack/react-query'

/* eslint-disable @typescript-eslint/consistent-type-definitions */
declare global {
  namespace NxDataProvider {
    export interface QueryMetaData {
      action?: BaseAction<any, any>
    }
  }
}
/* eslint-enable @typescript-eslint/consistent-type-definitions */

type SingularToPluralMap = typeof Api.singularToPluralMap

const EMPTY_ID = 'ACTION_EMPTY_ID' // Can't use symbol because of useMutation typing

export type BaseAction<
  Read extends Api.Resource,
  Write extends Api.Resource,
> = {
  resource: Write['@type'] extends keyof SingularToPluralMap
    ? SingularToPluralMap[Write['@type']]
    : string
  id?: Api.IriReference
  method?: 'put' | 'post' | 'delete'

  type: string
  payload?: Record<string, any>
  optimisticResult?: Partial<Read>
}

export type ExtractActionType<
  T extends Record<string, (...args: any[]) => BaseAction<any, any>>,
> = ReturnType<T[keyof T]>

export function useResourceAction<
  Write extends Api.Resource,
  Read extends Api.Resource,
>() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mutate, mutation] = useUpdate<
    Read,
    HttpError,
    Partial<Read | undefined>
  >()
  const queryClient = useQueryClient()
  const toastMessage = useToastMessage()

  function getMutateOptions(
    options?: Parameters<typeof mutate>[1],
  ): Parameters<typeof mutate>[1] {
    return {
      ...options,
      async onSettled(...args) {
        await options?.onSettled?.(...args)
        await queryClient.invalidateQueries()
      },

      async onError(error, ...args) {
        Sentry.captureException(error)
        if (options?.onError) {
          await options?.onError?.(error, ...args)
          return
        }

        toastMessage('error', 'notifications.actionError')
      },
      async onSuccess(...args) {
        if (options?.onSuccess) {
          await options?.onSuccess?.(...args)
          return
        }

        toastMessage('success', 'notifications.actionSuccess')
      },
    }
  }

  const result = {
    ...mutation,
    mutate<Action extends BaseAction<Read, Write>>(
      action: Action,
      options?: Parameters<(typeof mutation)['mutate']>[1],
    ) {
      const [params, mutateOptions] = actionMutateParams(action, options)
      mutation.mutate(params, getMutateOptions(mutateOptions))
    },
    async mutateAsync<Action extends BaseAction<Read, Write>>(
      action: Action,
      options?: Parameters<(typeof mutation)['mutate']>[1],
    ) {
      const [params, mutateOptions] = actionMutateParams(action, options)
      return mutation.mutateAsync(params, getMutateOptions(mutateOptions))
    },
  }

  return [result.mutateAsync, result] as const
}

export function actionMutateParams<
  Write extends Api.Resource,
  Read extends Api.Resource,
  Action extends BaseAction<Read, Write>,
>(
  action: Action,
  options?: Parameters<
    ReturnType<typeof useUpdate<Read, HttpError, Partial<Write | undefined>>>[0]
  >[1],
) {
  const { resource, id, optimisticResult } = action

  return [
    {
      resource,
      id: id ?? EMPTY_ID,
      values: optimisticResult ?? {},
      metaData: {
        action,
      },
      mutationMode: optimisticResult ? 'optimistic' : 'pessimistic',
    },
    options,
  ] as const
}

export type ActionHandler<
  ActionType extends BaseAction<any, any> = BaseAction<any, any>,
  MetaData extends Record<string, any> = object,
> = (
  dataProvider: DataProvider,
  action: ActionType,
  metaData: Partial<MetaData>,
) => Promise<unknown>

export function actionsProxy<
  ActionType extends BaseAction<any, any> = BaseAction<any, any>,
  MetaData extends Record<string, any> = object,
>(
  dataProvider: DataProvider,
  actionHandler: ActionHandler<ActionType, MetaData>,
): DataProvider {
  return {
    ...dataProvider,
    async update(params) {
      const { metaData } = params
      const action: ActionType | undefined = params.metaData?.action
      if (!action) {
        return dataProvider.update(params)
      }

      const result = await actionHandler(
        dataProvider,
        action,
        (metaData ?? {}) as Partial<MetaData>,
      )
      return { data: result as any }
    },
  }
}

export async function defaultActionRequest(
  dataProvider: DataProvider,
  action: BaseAction<any, any>,
  metaData: Record<string, any>,
) {
  const { resource, id, type, payload, method = 'put' } = action

  const noId = !id
  const { data } = await dataProvider.custom!({
    method,
    url: noId
      ? `/${resource}/${type}`
      : `/${resource}/${cleanHydraId(String(id))}/${type}`,
    metaData,
    payload,
  })
  return data
}
