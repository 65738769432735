import type { CampaignApiLists, CampaignApiRead } from '@aubade/types'
import {
  cleanHydraId,
  toHydraId,
  type DataProvider,
} from '@nartex/data-provider'

export function CampaignProxy(dataProvider: DataProvider): DataProvider {
  return {
    ...dataProvider,
    // @ts-expect-error any
    async getList() {
      const data = await dataProvider.custom<CampaignApiLists, unknown>({
        method: 'post',
        payload: {},
        url: '/conversation/informational/list',
      })
      return {
        ...data,
        data: data.data.conversations ?? [],
      }
    },
    // @ts-expect-error any

    async create(params) {
      const variables = params.variables as unknown as CampaignApiRead

      const { societies, agencies } = variables

      const cleanSocieties = societies?.map(cleanHydraId)
      const cleanAgencies = agencies?.map(cleanHydraId)

      const payload = {
        ...variables,
        societies: cleanSocieties,
        agencies: cleanAgencies,
      }

      return await dataProvider.custom({
        method: 'post',
        payload,
        url: '/conversation/informational',
      })
    },
    // @ts-expect-error any
    async getOne(params) {
      const { id } = params

      const data = await dataProvider.custom<CampaignApiRead>({
        method: 'get',
        url: `/conversation/informational/${cleanHydraId(id)}`,
      })

      const { societies, agencies } = data.data

      return {
        ...data,
        data: {
          ...data.data,
          societies: societies?.map((society) =>
            toHydraId('societies', society),
          ),
          agencies: agencies?.map((agency) => toHydraId('agencies', agency)),
        },
      }
    },
  }
}
