import { useOne } from '@aubade/core/adapters'
import {
  makeForm,
  booleanTransformer,
  BaseRadioButtons,
} from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isAdmin, isSociety } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { PublicationForm, Contact } from '@aubade/types'
import { cleanHydraId, toHydraId } from '@nartex/api-platform'
import { useEffect } from 'react'
import { SocietyAgencyFilter } from 'src/components/Filters'

import { AgencyFilter } from '../../../components/Filters/AgencyFilters'

const { Input, useFormContext, useWatch } = makeForm<
  PublicationForm,
  'isNational' | 'isAgencies' | 'agencies' | 'societies'
>()

const targetMapToLabel: Record<Target, string> = {
  notification: 'notifications.fields.targets',
  publication: 'publications.fields.target',
  conversation: 'conversation.fields.targets',
}

type Target = 'notification' | 'publication' | 'conversation'

type Props = {
  target?: Target
  readOnly?: boolean
}

export function TargetSection(props: Props) {
  const { role, agency } = useConnectedUser()
  if (isAdmin(role)) {
    return <AubadeTargetList {...props} />
  }
  if (isSociety(role)) {
    return <AgencyTargetList {...props} />
  }
  return <SingleAgencyTarget agencyId={agency?.id} {...props} />
}

function AubadeTargetList(props: Props) {
  const { target = 'publication', readOnly = false } = props
  const { setValue } = useFormContext()

  const translate = useTranslate()
  const isNational = useWatch('isNational')

  if (isNational === undefined) return <></>

  const label = targetMapToLabel[target]

  return (
    <>
      <Input.RadioButtons<boolean>
        name="isNational"
        label={label}
        readOnly={readOnly}
        onChange={() => {
          setValue('agencies', [])
          setValue('societies', [])
        }}
        options={{
          true: translate('publications.fields.targetNational'),
          false: translate('publications.fields.targetSocieties'),
        }}
        transformer={booleanTransformer}
      />
      {!isNational && <SocietyAgencyFilter readOnly={readOnly} />}
    </>
  )
}

export function AgencyTargetList(props: Props) {
  const { target = 'publication', readOnly = false } = props
  const translate = useTranslate()
  const { id: me } = useConnectedUser()
  const { setValue } = useFormContext()

  const isAgencies = useWatch('isAgencies')

  const [actualUser] = useOne<Contact>({
    iri: toHydraId('users', me),
  })

  const societyId = cleanHydraId(actualUser!.society!['@id'])

  if (isAgencies === undefined) return <></>

  const label = targetMapToLabel[target]

  return (
    <>
      <Input.RadioButtons<boolean>
        name="isAgencies"
        label={label}
        readOnly={readOnly}
        onChange={(value) => {
          if (value) {
            setValue('agencies', [])
            setValue('societies', [toHydraId('societies', societyId)])
            setValue('isNational', false)
          } else {
            setValue('isNational', false)
            setValue('societies', [])
          }
        }}
        options={{
          true: translate('publications.fields.targetSociety'),
          false: translate('publications.fields.targetAgencies'),
        }}
        transformer={booleanTransformer}
      />
      {isAgencies === false && <AgencyFilter />}
    </>
  )
}

function SingleAgencyTarget(props: Props & { agencyId?: string }) {
  const { setValue } = useFormContext()

  const { agencyId, target = 'publication', readOnly = false } = props

  useEffect(() => {
    if (!agencyId) return
    setValue('isNational', false)
  }, [agencyId, setValue])

  if (!agencyId) return <></>

  const label = targetMapToLabel[target]

  return (
    <>
      <Input.Hidden name={'agencies'} value={[agencyId]} />
      <BaseRadioButtons
        readOnly={readOnly}
        label={label}
        value={'true'}
        disabled
        options={{
          true: 'Mon agence',
        }}
      />
    </>
  )
}
