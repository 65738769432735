import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  Button,
  Form,
  makeForm,
  Paragraph,
  Surface,
  unit,
} from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import { useConnectedUser } from '@aubade/domain/adapters'
import type {
  Contact,
  ExtendedOneConversation,
  ExtendedUserConversation,
} from '@aubade/types/index'
import { Box, VStack } from '@chakra-ui/react'
import { useFormController, useFormState } from '@nartex/react-libs'
import { useMutation } from '@tanstack/react-query'
import { useUrls } from 'src/App'

import { useAubadeQueryBuilder } from '../../../aubadeQueryBuilder/useAubadeQueryBuilder'
import { UserCard } from '../../../components/ContactPicker'

type FormType = {
  name: string
}

const { Input } = makeForm<FormType>()

type Props = {
  conversation: ExtendedOneConversation
  onClick: () => void
}

export function ConversationInfos(props: Props) {
  const { conversation, onClick } = props
  const { name } = conversation

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  const { mutateAsync: renameConversation } = useMutation(
    aubadeQueryBuilder.renameConversation(conversation.id!),
  )

  const { saveButtonProps, ...methods } = useFormController<FormType>({
    hookFormOptions: {
      defaultValues: {
        name,
      },
    },
    async onValid(data) {
      await renameConversation(data)
      methods.reset(data)
    },
  })

  return (
    <Surface
      width={'450px'}
      height={`calc(100%)`}
      maxHeight={`calc(100%)`}
      direction={'column'}
      onClick={(e) => e.stopPropagation()}
      backgroundColor="lightGrey.500"
      position="relative"
      padding={unit('60')}
      alignItems={'flex-start'}
      justifyContent={'flex-start'}
      overflow="auto"
    >
      <Form {...methods}>
        <Box position="absolute" top={unit('20')} right={unit('20')}>
          <Button
            variant="circleWhite"
            leftIcon={IconFermer}
            autoFocus={false}
            onClick={onClick}
          />
        </Box>
        <FormContent {...saveButtonProps} conversation={conversation} />
      </Form>
    </Surface>
  )
}

type FormContentProps = FormSubmitProps & {
  conversation: ExtendedOneConversation
}

function FormContent(props: FormContentProps) {
  const { conversation, ...saveButtonProps } = props
  const { isDirty } = useFormState()

  const { id: me } = useConnectedUser()

  const { users } = conversation

  const isGroup = (users ?? []).length > 2

  const filteredUsers = isGroup
    ? users
    : (users ?? []).filter((user) => user.id !== me)

  return (
    <VStack gap={unit('60')} alignItems={'flex-start'} width="full">
      {isGroup && (
        <VStack gap={unit('30')} alignItems={'flex-start'} width="full">
          <Paragraph
            fontWeight="bold"
            size="lg"
            text="conversations.title.name"
          />
          <Input.Text name="name" />
          <Button
            {...saveButtonProps}
            variant="primary"
            label="buttons.save"
            isDisabled={!isDirty}
          />
        </VStack>
      )}
      <VStack gap={unit('30')} alignItems={'flex-start'} width="full">
        <Paragraph
          fontWeight="bold"
          size="lg"
          text="conversations.title.detail"
        />
        <VStack gap={unit('10')} alignItems={'flex-start'} maxWidth="full">
          {filteredUsers?.map((user) => {
            return <UserDetail user={user} key={user.id} />
          })}
        </VStack>
      </VStack>
    </VStack>
  )
}

type UserDetailsPorps = {
  user: ExtendedUserConversation
}

function UserDetail(props: UserDetailsPorps) {
  const { user } = props
  const { craftCompany, id } = user
  const urls = useUrls()
  return (
    <Box width="full">
      <UserCard
        origin="api"
        user={user as Contact}
        crafter={Boolean(craftCompany)}
        url={urls
          .aubade()
          .userDetail(craftCompany ? 'clients' : 'collaborateurs', id)}
      />
    </Box>
  )
}
