// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconStats = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.1669 11.5381C46.284 11.5381 48.0002 13.2601 48.0002 15.3843L48.0002 46.1535C48.0002 48.2777 46.284 49.9997 44.1669 49.9997C42.0497 49.9997 40.3335 48.2777 40.3335 46.1535L40.3335 15.3843C40.3335 13.2601 42.0498 11.5381 44.1669 11.5381Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 -1.54111e-07C27.1171 -6.89977e-08 28.8334 1.72198 28.8334 3.84615L28.8334 46.1538C28.8334 48.278 27.1171 50 25 50C22.8829 50 21.1666 48.278 21.1666 46.1538L21.1666 3.84615C21.1666 1.72198 22.8829 -2.39224e-07 25 -1.54111e-07Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83362 26.919C7.95073 26.919 9.66699 28.641 9.66699 30.7652L9.66699 46.1498C9.66699 48.2739 7.95073 49.9959 5.83361 49.9959C3.7165 49.9959 2.00024 48.2739 2.00024 46.1498L2.00024 30.7652C2.00024 28.641 3.7165 26.919 5.83362 26.919Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconStats)
export default Memo
