import { drawerQueryParams, useQueryParamsState } from '@aubade/core/libs'
import { FormDrawer } from '@aubade/core/ui-kit'

import { useConnectedUser } from '../../adapters'

import { FormEdit } from './FormEdit'

export function Profile() {
  const { id: me } = useConnectedUser()
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page } = modalState
  const matchUpdate = page === 'profile'

  return (
    <FormDrawer isOpenProps={Boolean(matchUpdate)} variant="users">
      {matchUpdate && me && <FormEdit id={me} profile="collaborater" />}
    </FormDrawer>
  )
}
