import { createContextFunction } from '@aubade/core/libs'
import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import type { As, StackProps } from '@chakra-ui/react'
import { Box, Flex } from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import { Providers } from 'join-react-context'
import type {
  DOMAttributes,
  FormEvent,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { Suspense } from 'react'
import type { FieldValues, FormProviderProps } from 'react-hook-form'
import { FormProvider, useFormContext } from 'react-hook-form'

import { Accordion } from '../Accordion'
import { Button, type ButtonProps } from '../Button'
import type { Bucket } from '../Inputs/DropZoneInput/DropZoneInput'
import { BucketProvider } from '../Inputs/DropZoneInput/DropZoneInput'
import { Title } from '../Title'
import { unit, type Unit } from '../utils'

export * from './useForm'
export * from './LabeledField'
export * from './libs'
export * from './makeForm'

type FormProps<TFieldValues extends FieldValues, TContext> = FormProviderProps<
  TFieldValues,
  TContext
> & {
  onSubmit?: DOMAttributes<HTMLFormElement>['onSubmit']
  direction?: StackProps['direction']
  alignItems?: StackProps['alignItems']
  gap?: Unit
  readOnly?: boolean
  BusyProvider?: (props: PropsWithChildren<object>) => ReactNode
  HasSuggestionsProvider?: (props: PropsWithChildren<object>) => ReactNode
  bucket?: Bucket
  noDevtools?: boolean
  id?: string
  as?: As
}
export const Form = Object.assign(
  function Form<TFieldValues extends FieldValues, TContext = any>(
    props: PropsWithChildren<FormProps<TFieldValues, TContext>>,
  ) {
    const isRootForm = !useFormContext()
    const defaultAs = isRootForm ? 'form' : 'div'
    const {
      direction = 'column',
      alignItems = 'flex-start',
      gap = '10',
      onSubmit,
      children,
      readOnly = false,
      bucket,
      noDevtools,
      id,
      as = defaultAs,
      ...methods
    } = props

    return (
      <Providers
        providers={
          <>
            {/* set children to undefined to satisfy typescript typings */}
            <FormProvider {...methods}>{undefined}</FormProvider>
            <ReadOnlyProvider value={readOnly} />
            <BucketProvider value={bucket} />
          </>
        }
      >
        <Box
          as={as}
          id={id}
          style={{ width: '100%' }}
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.stopPropagation()
            onSubmit?.(event)
          }}
        >
          <Accordion
            display={'flex'}
            alignItems={alignItems}
            flexDirection={direction}
            gap={unit(gap)}
          >
            <Suspense fallback={<></>}>{children}</Suspense>
          </Accordion>
        </Box>
        {!noDevtools &&
          isRootForm &&
          process.env.NODE_ENV === 'development' && (
            <DevTool control={methods.control} />
          )}
      </Providers>
    )
  },
  {
    Section: FormSection,
    Submit: FormSubmitButton,
  },
)

// old suspense fallback: <Skeletons />

// possibility to extend this component to decorate sections later
type FormSectionProps = {
  label?: TranslationKey
  direction?: StackProps['direction']
  gap?: StackProps['gap']
}
function FormSection(props: PropsWithChildren<FormSectionProps>) {
  const { label, children, direction = 'column', gap = 9 } = props
  const translate = useTranslate()
  return (
    <Flex
      alignItems={'flex-start'}
      direction={direction}
      gap={gap}
      width="100%"
      role="group"
      aria-label={label ? translate(label) : undefined}
    >
      {label ? <Title.H5 fontWeight="bold" text={label} id={label} /> : null}
      {children}
    </Flex>
  )
}

// possibility to extend this component to handle loading states etc.
export type FormSubmitProps = Pick<
  ButtonProps,
  'isLoading' | 'isDisabled' | 'label' | 'onClick'
>
function FormSubmitButton(props: PropsWithChildren<FormSubmitProps>) {
  return <Button {...props} variant="primary" type="submit" />
}

export const [ReadOnlyProvider, useReadOnly] =
  createContextFunction<boolean>('readOnly2')
