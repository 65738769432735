// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconEyeHide = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.0251 2.62593C36.9195 1.98762 35.5057 2.36642 34.8674 3.47202L11.3603 44.1876C10.7219 45.2932 11.1007 46.7069 12.2063 47.3453C13.3119 47.9836 14.7257 47.6048 15.364 46.4992L38.8711 5.78357C39.5095 4.67797 39.1307 3.26425 38.0251 2.62593ZM29.1583 7.2799C27.8568 7.09476 26.4745 7 25 7C12.4771 7 6.61147 13.8344 0.311128 24.9108L0 25.4607L0.311128 26.0892C3.54086 31.727 6.65636 36.2913 10.613 39.4013L13.57 34.2796C11.7142 31.8322 10.6103 28.774 10.6103 25.4607C10.6103 17.448 17.0662 10.9278 25 10.9278C25.6694 10.9278 26.3282 10.9742 26.9735 11.064L29.1583 7.2799ZM22.7356 39.8139C23.4734 39.9322 24.2297 39.9936 25 39.9936C32.9338 39.9936 39.3897 33.4735 39.3897 25.4607C39.3897 22.041 38.2138 18.8932 36.2489 16.408L39.1519 11.3799C43.2208 14.472 46.3955 19.0833 49.6889 24.8323L50 25.4607L49.6889 26.0892C43.3885 37.1656 37.5229 44 25 44C23.3998 44 21.9084 43.8871 20.5108 43.6673L22.7356 39.8139ZM24.0381 16.0587C19.291 16.5864 15.5996 20.6121 15.5996 25.5C15.5996 26.8523 15.8822 28.1386 16.3914 29.3031L24.0381 16.0587ZM25.5733 34.9884C30.5999 34.7416 34.5996 30.5879 34.5996 25.5C34.5996 23.938 34.2226 22.4641 33.5547 21.1642L25.5733 34.9884Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconEyeHide)
export default Memo
