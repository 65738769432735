// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconProfil = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.6662 11.6668C13.6662 8.57261 14.8953 5.60515 17.0831 3.41737C19.2709 1.22959 22.2384 0 25.3325 0C28.4266 0 31.3942 1.22959 33.5819 3.41737C35.7697 5.60515 36.9988 8.57273 36.9988 11.6668C36.9988 14.7609 35.7697 17.728 33.5819 19.9162C31.3942 22.104 28.4266 23.3331 25.3325 23.3331C22.2393 23.3298 19.2742 22.0993 17.0869 19.9125C14.8996 17.7252 13.6695 14.7595 13.6662 11.6668ZM28.6662 26.6668H22C18.0228 26.6715 14.2103 28.2534 11.3985 31.0652C8.58664 33.8771 7.00475 37.69 7 41.6667C7.00286 43.876 7.8816 45.9938 9.44345 47.5561C11.0057 49.1183 13.124 49.9971 15.3333 50H35.3332C37.5425 49.9971 39.6608 49.1184 41.223 47.5561C42.7848 45.9938 43.6636 43.876 43.6665 41.6667C43.6617 37.69 42.0799 33.8771 39.268 31.0652C36.4562 28.2534 32.6437 26.6715 28.6665 26.6668H28.6662Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconProfil)
export default Memo
