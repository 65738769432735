// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconFermer = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.453 25L49.0777 5.37536C49.8732 4.57983 50.1836 3.42049 49.8927 2.33381C49.6015 1.24741 48.7526 0.398493 47.6662 0.107249C46.5794 -0.183621 45.4202 0.12678 44.6246 0.922314L25 20.5469L5.37536 0.922314C4.57983 0.126775 3.42049 -0.183648 2.33381 0.107249C1.24741 0.398467 0.398493 1.24741 0.107249 2.33381C-0.183621 3.42058 0.12678 4.57984 0.922314 5.37536L20.5469 25L0.922314 44.6246C0.126776 45.4202 -0.183648 46.5795 0.107249 47.6662C0.398466 48.7526 1.24741 49.6015 2.33381 49.8927C3.42058 50.1836 4.57984 49.8732 5.37536 49.0777L25 29.453L44.6246 49.0777C45.4202 49.8732 46.5795 50.1836 47.6662 49.8927C48.7526 49.6015 49.6015 48.7526 49.8927 47.6662C50.1836 46.5794 49.8732 45.4202 49.0777 44.6246L29.453 25Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconFermer)
export default Memo
