// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconArrowBottom = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={51}
    height={50}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.9934 38.9096C26.5395 40.3634 24.1816 40.3634 22.7278 38.9096L1.45158 17.6334C-0.00225377 16.1789 -0.00225387 13.8216 1.45158 12.3678C2.90542 10.9134 5.26337 10.9134 6.71715 12.3678L25.3612 31.0119L44.0053 12.3678C45.4591 10.9134 47.817 10.9134 49.2708 12.3678C50.7247 13.8216 50.7247 16.179 49.2708 17.6334L27.9934 38.9096Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconArrowBottom)
export default Memo
