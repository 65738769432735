import { unit } from '@aubade/core/ui-kit'
import { VStack } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

export function DrawerContent(props: PropsWithChildren<object>) {
  return (
    <VStack
      width="full"
      height="full"
      gap={unit('40')}
      paddingX={unit('60')}
      alignItems={'flex-start'}
    >
      {props.children}
    </VStack>
  )
}
