import { useHTTPClient } from '@aubade/core/adapters'
import { useEvent } from '@aubade/core/libs'
import { useToastMessage } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import type { ExtendedUser } from '@aubade/types/index'
import type { MessagePayload } from '@firebase/messaging'
import type { FirebaseError } from '@firebase/util'
import {
  toHydraId,
  useOne,
  useQueryMetaData,
} from '@nartex/data-provider/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { FirebaseApp } from 'firebase/app'
import { initializeApp } from 'firebase/app'
import type { Messaging } from 'firebase/messaging'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { once, unique } from 'remeda'

import { Env } from './Env'

const firebaseConfig = Env().FIREBASE_CONFIG
const vapidKey = Env().FIREBASE_VAPID_KEY

const params = new URLSearchParams(firebaseConfig)

const registerServiceWorker = once(async function registerServiceWorker() {
  // serviceWorker can be undefined on private navigation
  return await navigator.serviceWorker?.register(
    `firebase-messaging.sw.js?${params.toString()}`,
  )
})

export function useRequestPermission(isAuthenticated: boolean) {
  const { setToken } = usePostTokenToAPi(isAuthenticated)
  const toast = useToastMessage()

  const { mutate } = useMutation<unknown, FirebaseError>({
    mutationFn: async () => {
      const permission = await Notification.requestPermission()
      if (permission !== 'granted') return
      await setToken()
    },
    onError(error: any) {
      toast('error', error.message)
    },
  })
  return () => {
    return mutate()
  }
}

export function usePostTokenToAPi(isAuthenticated: boolean) {
  const { id: me } = useConnectedUser()
  const [user] = useOne<ExtendedUser>(
    { iri: toHydraId('users', me ?? '') },
    { enabled: Boolean(me && isAuthenticated) },
  )
  const firebase = useFirebase()

  const httpClient = useHTTPClient()
  const metaData = useQueryMetaData()

  async function removeToken() {
    if (!firebase || !user || !isAuthenticated) return
    const actualToken = await firebase?.getToken?.()
    const tokens = user?.registrationTokensBackOffice

    const newTokens = (tokens ?? []).filter((t) => t !== actualToken)

    await httpClient.request({
      method: 'put',
      url: `/users/${me}/setRegistrationTokens/back-office`,
      data: {
        registrationTokens: newTokens,
      },
      headers: metaData?.headers,
    })
  }

  async function setToken() {
    if (!user || !isAuthenticated) return
    const actualToken = await firebase?.getToken()
    const tokens = user?.registrationTokensBackOffice
    const newTokens = unique([...(tokens ?? []), actualToken])

    await httpClient.request({
      method: 'put',
      url: `/users/${me}/setRegistrationTokens/back-office`,
      data: {
        registrationTokens: newTokens,
      },
      headers: metaData?.headers,
    })
  }

  return {
    removeToken: useEvent(async () => await removeToken()),
    setToken: useEvent(async () => await setToken()),
  }
}

class Firebase {
  private app: FirebaseApp
  private messaging: Messaging
  private serviceWorkerRegistration?: ServiceWorkerRegistration

  static async init() {
    return new Firebase(await registerServiceWorker())
  }

  constructor(serviceWorkerRegistration?: ServiceWorkerRegistration) {
    this.app = initializeApp(firebaseConfig)

    this.messaging = getMessaging(this.app)

    this.serviceWorkerRegistration = serviceWorkerRegistration
  }

  async getToken(): Promise<string> {
    try {
      return await getToken(this.messaging, {
        serviceWorkerRegistration: this.serviceWorkerRegistration,
        vapidKey,
      })
    } catch (error) {
      // https://github.com/firebase/firebase-js-sdk/issues/2364
      if ((error as any)?.code === 'messaging/token-unsubscribe-failed') {
        return this.getToken()
      } else {
        throw error
      }
    }
  }

  onMessage(callback: (payload: MessagePayload) => void) {
    return onMessage(this.messaging, callback)
  }
}

export function useFirebase() {
  const toast = useToastMessage()
  const { data } = useQuery({
    queryFn: async () => {
      try {
        return await Firebase.init()
      } catch (error) {
        logOnce((error as Error)?.message, () => {
          if (process.env.NODE_ENV === 'development') {
            console.error(error)
          }
          toast('error', (error as Error)?.message)
        })

        return null
      }
    },
    queryKey: ['firebase', 'initialize'],
    suspense: true,
    useErrorBoundary: false,
    retry: false,
    staleTime: Infinity,
  })
  return data
}

const logged = new Set<string>()
function logOnce(message: string, cb: () => void) {
  if (!logged.has(message)) {
    logged.add(message)
    cb()
  }
}
