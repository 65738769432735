import type { LogicalFilter } from '@aubade/core/adapters'
import { unit, Paragraph, makeForm } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import { isSociety } from '@aubade/domain/components'
import type { UserRole } from '@aubade/types'
import { HStack, VStack } from '@chakra-ui/react'
import { isEmpty, run } from '@nartex/stdlib'
import { differenceInDays } from 'date-fns'
import type { EChartsOption } from 'echarts'
import ReactEcharts from 'echarts-for-react'
import { useMemo, type BaseSyntheticEvent } from 'react'
import { Trans } from 'react-i18next'

import { RadioButtons } from '../../../../../../packages/core/src/ui-kit/Inputs/RadioButtons/RadioButtons'
import { getTotalStats } from '../../../adapters/antiCorruptionLayerProxy/proxies/statistics'
import { type StatisticsFiltersType } from '../Filters'
import type { TemporaryStatSummary } from '../types'

import { useGetStats } from './useGetStats'

const { Input } = makeForm<
  StatisticsFiltersType,
  'agencies' | 'societies' | 'date' | 'displayType'
>()

type AverageStats = {
  notifications: number
  publications: number
}

type GraphSectionProps = {
  onChange: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
  options?: Record<string, string>
  crudFilters: LogicalFilter<TemporaryStatSummary>[]
  filterState: StatisticsFiltersType
}
export function GraphSection(props: GraphSectionProps) {
  const { onChange, filterState, crudFilters } = props

  const stats = useGetStats(crudFilters)

  if (!stats) return <></>

  const { graph } = stats[0]

  const { sommePublications, sommeNotifications } = getTotalStats(stats[0])

  return (
    <VStack
      width="full"
      backgroundColor={'white'}
      padding={unit('60')}
      borderRadius={unit('30')}
      gap={unit('20')}
    >
      <Header
        total={{
          notifications: sommeNotifications,
          publications: sommePublications,
        }}
        onChange={onChange}
        filterState={filterState}
      />

      {graph.length ? <Graph datas={graph} /> : <NoGraph />}
    </VStack>
  )
}

type HeaderProps = Pick<GraphSectionProps, 'onChange' | 'filterState'> & {
  total: AverageStats
}

function Header(props: HeaderProps) {
  const { total, onChange, filterState } = props
  const { notifications, publications } = total

  const { role } = useConnectedUser()

  const { agencies, date } = filterState

  const options = useMemo(() => {
    return getRadioOptions(filterState, role)
  }, [filterState, role])

  const readOnlyVal = run(() => {
    if (date && date?.endDate && date?.startDate) {
      if (
        differenceInDays(new Date(date?.endDate), new Date(date!.startDate!)) >
        30
      ) {
        return 'month'
      } else {
        return 'day'
      }
    }
    return ''
  })

  return (
    <HStack width="full" justifyContent={'space-between'}>
      <Trans
        i18nKey="statistics.graph.total"
        components={{
          paragraph: (
            <Paragraph
              whiteSpace={'pre'}
              textAlign={'center'}
              fontWeight="bold"
            />
          ),
          publications: (
            <span style={{ fontWeight: 'bold', color: '#009EE0' }} />
          ),
          notifications: (
            <span style={{ fontWeight: 'bold', color: '#CE0E2D' }} />
          ),
        }}
        values={{
          publications: publications ?? 0,
          notifications: notifications ?? 0,
        }}
      />
      <HStack>
        {agencies?.length ? (
          <RadioButtons options={options} readOnly value={readOnlyVal} />
        ) : (
          <Input.RadioButtons
            name="displayType"
            borderColor="grey.500"
            inline={true}
            options={options}
            onChange={() => onChange()}
          />
        )}
      </HStack>
    </HStack>
  )
}

export function getRadioOptions(
  filters: StatisticsFiltersType,
  role?: UserRole,
): Record<string, string> {
  const { date, societies, agencies } = filters
  const options: Record<string, string> = {}

  if (!date || !date.startDate || !date.endDate) {
    return {}
  }

  if (
    differenceInDays(new Date(date!.endDate!), new Date(date!.startDate!)) > 30
  ) {
    if (!isEmpty(societies)) {
      Object.assign(options, { month: 'filters.displayType.month.society' })
    } else {
      Object.assign(options, { month: 'filters.displayType.month.national' })
    }
  } else {
    if (!isEmpty(societies)) {
      Object.assign(options, { day: 'filters.displayType.day.society' })
    } else {
      Object.assign(options, { day: 'filters.displayType.day.national' })
    }
  }
  if (isEmpty(societies) && isEmpty(agencies) && !isSociety(role)) {
    Object.assign(options, { society: 'filters.displayType.society' })
  }
  if (!isEmpty(societies) || isSociety(role)) {
    Object.assign(options, { agency: 'filters.displayType.agency' })
  }
  if (!isEmpty(agencies)) {
    if (
      differenceInDays(new Date(date!.endDate!), new Date(date!.startDate!)) >
      30
    ) {
      return { month: 'filters.displayType.month.readonly' }
    }
    return { day: 'filters.displayType.day.readonly' }
  }
  return options
}

function NoGraph() {
  return <Paragraph text="graph.noData" />
}

function getOptions(
  datas: TemporaryStatSummary['graph'],
): EChartsOption | undefined {
  const xLabels = datas.map((data) => data.name)
  const publications = datas.map((data) => {
    return data.publications
  })
  const notifications = datas.map((data) => {
    return data.notifications
  })
  const transformedDatas = {
    labels: xLabels,
    publications,
    notifications,
  }

  const itemStyle = {
    borderRadius: 10,
  }

  return {
    legend: {},
    tooltip: {},
    backgroundColor: 'rgb(243, 245, 247)',
    xAxis: {
      data: transformedDatas.labels,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      axisLabel: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
    },
    grid: {
      backgroundColor: 'rgb(243, 245, 247)',
      borderWidth: 0,
      show: true,
      top: 60,
      bottom: 60,
      left: 60,
      right: 60,

      containLabel: true,
    },

    series: [
      {
        type: 'bar',
        data: transformedDatas.publications,
        itemStyle: { ...itemStyle, color: '#009EE0' },
        barWidth: 4,
        barGap: '160%',
        large: true,
      },
      {
        type: 'bar',
        data: transformedDatas.notifications,
        itemStyle: { ...itemStyle, color: '#CE0E2D' },
        barWidth: 4,
        barGap: '160%',
        barCategoryGap: '10px',
        large: true,
      },
    ],
  }
}

type GraphProps = {
  datas: TemporaryStatSummary['graph']
}

function Graph(props: GraphProps) {
  const { datas } = props
  const options = getOptions(datas)
  return (
    <ReactEcharts
      option={options}
      lazyUpdate={true}
      style={{
        width: '100%',
        height: '500px',
        borderRadius: '20px',
      }}
    />
  )
}
