import { useOne } from '@aubade/core/adapters'
import { drawerQueryParams, useQueryParamsState } from '@aubade/core/libs'
import { FormDrawer } from '@aubade/core/ui-kit'
import { useConnectedUser } from '@aubade/domain/adapters'
import { FormCreate, FormEdit } from '@aubade/domain/resources'
import type { Contact } from '@aubade/types'
import { toHydraId } from '@nartex/api-platform'

type Props = {
  profile: 'customer' | 'collaborater'
}

export function EditUser(props: Props) {
  const { profile } = props
  const [modalState] = useQueryParamsState(drawerQueryParams)

  const { page, id } = modalState
  const matchUpdate = page === 'edit' && id
  return (
    <FormDrawer isOpenProps={Boolean(matchUpdate)} variant="users">
      {matchUpdate && <FormEdit id={id} profile={profile} />}
    </FormDrawer>
  )
}

export function CreateUser() {
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page } = modalState
  const matchCreate = page === 'create'
  const { id: me } = useConnectedUser()

  const [actualUser] = useOne<Contact>({ iri: toHydraId('users', me) })
  if (!actualUser) return <></>

  return (
    <FormDrawer isOpenProps={Boolean(matchCreate)}>
      {matchCreate && <FormCreate />}
    </FormDrawer>
  )
}
