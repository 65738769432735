import type { Merge } from '@aubade/core/libs'
import { makeForm, Chip, QueryOne } from '@aubade/core/ui-kit'
import { IconFermer } from '@aubade/design/graphics'
import type { Society, Agency } from '@aubade/types/api'
import { toHydraId } from '@nartex/api-platform'
import { useList } from '@nartex/data-provider/react'

import type { JobEventJob } from '../../../../../packages/types/src/api/index'

type AgenciesAndSocieties = Merge<Society.Read, Agency.Read>

type ActiveFilterType = {
  agencies: string[]
  societies: string[]
  me: boolean
  jobs: string[]
  roles: string[]
  internal: boolean
  external: boolean
  isNational: boolean
}

const { useWatch, useFormContext } = makeForm<
  ActiveFilterType,
  | 'agencies'
  | 'societies'
  | 'me'
  | 'jobs'
  | 'roles'
  | 'internal'
  | 'external'
  | 'me'
  | 'isNational'
>()

type Props = {
  onChange: () => void
  variant?: 'negative'
  scope?: 'notifications' | 'publications'
}

export function JobActiveFilters(props: Props) {
  const { onChange, variant } = props

  const [jobs] = useWatch(['jobs'])
  const [categories] = useList<JobEventJob.Read>({
    resource: 'jobs/list',
    hasPagination: false,
  })

  const { setValue } = useFormContext()
  return (
    <>
      {jobs?.map((job) => {
        const category = categories?.find((cat) => cat.id === job && cat.label)

        const label = category ? category.label : ''

        return (
          <FilterChip
            key={job}
            label={label}
            variant={variant}
            onClick={() => {
              const newJobs = jobs.filter((iri) => iri !== job)
              if (newJobs.length > 0) {
                setValue('jobs', newJobs)
              } else {
                setValue('jobs', undefined)
              }
              onChange()
            }}
          />
        )
      })}
    </>
  )
}

export function AuthorActiveFilters(props: Props) {
  const { onChange, scope } = props
  const { setValue } = useFormContext()
  const [author] = useWatch(['me'])
  return (
    <>
      {author && scope && (
        <FilterChip
          label={`filters.${scope}.me`}
          onClick={() => {
            setValue(`me`, undefined)
            onChange()
          }}
        />
      )}
    </>
  )
}

export function SocietiesActiveFilters(props: Props) {
  const { onChange, variant } = props
  const { setValue } = useFormContext()
  const societies = useWatch('societies')
  const filteredSocieties = societies?.filter(
    (society) => society !== undefined,
  )
  return (
    <>
      {filteredSocieties?.map((society) => {
        return (
          <QueryOne<AgenciesAndSocieties>
            key={society}
            iri={toHydraId('societies', society)}
            children={(record) => {
              return (
                <FilterChip
                  label={record.name}
                  variant={variant}
                  onClick={() => {
                    const newSocieties = filteredSocieties.filter(
                      (iri) => iri !== toHydraId('societies', society),
                    )
                    if (newSocieties.length > 0) {
                      setValue('societies', newSocieties)
                    } else {
                      setValue('societies', undefined)
                    }
                    onChange()
                  }}
                />
              )
            }}
          />
        )
      })}
    </>
  )
}

export function AgenciesActiveFilter(props: Props) {
  const { onChange, variant } = props
  const { setValue } = useFormContext()
  const agencies = useWatch('agencies')
  const filteredAgencies = agencies?.filter((agency) => agency !== undefined)
  return (
    <>
      {filteredAgencies?.map((agency) => {
        return (
          <QueryOne<AgenciesAndSocieties>
            key={agency}
            iri={toHydraId('agencies', agency)}
            children={(record) => {
              return (
                <QueryOne<Society.Read>
                  key={`society - ${agency}`}
                  // @ts-ignore
                  iri={record.society['@id']}
                  children={(society) => {
                    const label = [society.name, record.name]
                      .filter(Boolean)
                      .join(': ')
                    return (
                      <FilterChip
                        label={label}
                        variant={variant}
                        onClick={() => {
                          const newAgencies = filteredAgencies.filter(
                            (iri) => iri !== agency,
                          )
                          if (newAgencies.length > 0) {
                            setValue('agencies', newAgencies)
                          } else {
                            setValue('agencies', undefined)
                          }
                          onChange()
                        }}
                      />
                    )
                  }}
                />
              )
            }}
          />
        )
      })}
    </>
  )
}

export function RolesActiveFilters(props: Props) {
  const { onChange } = props
  const { setValue } = useFormContext()
  const roles = useWatch('roles')
  const filteredRoles = roles?.filter((role) => role !== undefined)
  return (
    <>
      <>
        {filteredRoles &&
          filteredRoles?.map((role) => {
            return (
              <FilterChip
                key={role}
                label={role}
                onClick={() => {
                  const newRoles = filteredRoles.filter((iri) => iri !== role)
                  if (newRoles.length > 0) {
                    setValue('roles', newRoles)
                  } else {
                    setValue('roles', undefined)
                  }
                  onChange()
                }}
              />
            )
          })}
      </>
    </>
  )
}

export function ActiveProfileConversationFilters(props: Props) {
  const { onChange, variant } = props
  const { setValue } = useFormContext()
  const [internal, external] = useWatch(['internal', 'external'])
  return (
    <>
      {internal && (
        <FilterChip
          label="filters.publications.internal"
          variant={variant}
          onClick={() => {
            setValue(`internal`, false)
            onChange()
          }}
        />
      )}
      {external && (
        <FilterChip
          label="filters.publications.external"
          variant={variant}
          onClick={() => {
            setValue(`external`, false)
            onChange()
          }}
        />
      )}
    </>
  )
}

export function IsNationalFilters(props: Props) {
  const { onChange, variant } = props
  const { setValue } = useFormContext()
  const isNatinal = useWatch('isNational')
  return (
    <>
      {isNatinal && (
        <FilterChip
          label="filters.publications.isNational"
          variant={variant}
          onClick={() => {
            setValue(`isNational`, false)
            setValue(`isNational`, undefined)
            onChange()
          }}
        />
      )}
    </>
  )
}

type FilterChipProps = {
  label: string
  onClick: () => void
  variant?: 'negative' | 'default'
}

function FilterChip(props: FilterChipProps) {
  const { label, onClick, variant = 'default' } = props
  return (
    <Chip
      size="xsmall"
      label={label}
      iconLeftProps={{
        as: IconFermer,
        color: 'darkGrey.500',
      }}
      textProps={{
        color: 'darkGrey.500',
        fontWeight: 'bold',
      }}
      onClick={onClick}
      backgroundColor={variant === 'negative' ? 'white' : undefined}
    />
  )
}
