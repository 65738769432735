import type {
  DataProvider,
  DataProviderName,
} from '@nartex/data-provider/react'
import { useHistory } from '@nartex/react-libs'
import type { I18nProvider, IRouterProvider } from '@pankod/refine-core'
import { handleUseParams, Refine } from '@pankod/refine-core'
import { type QueryClient } from '@tanstack/react-query'
import { useEffect, type PropsWithChildren } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  type RouteProps,
} from 'react-router-dom'

export type RefineRouteProps = RouteProps & {
  layout?: boolean
}

type IReactRouterProvider = {
  useLocation: typeof useLocation
  Link: typeof Link
  useParams: <
    Params extends { [K in keyof Params]?: string } = Record<string, never>,
  >() => Params
  routes?: RefineRouteProps[]
} & IRouterProvider

const customRouterProvider: IReactRouterProvider = {
  useHistory() {
    const navigate = useNavigate()

    return {
      push: navigate,
      replace: (path: string) => {
        navigate(path, { replace: true })
      },
      goBack: () => {
        navigate(-1)
      },
    }
  },
  useLocation,
  useParams() {
    const params = useParams()
    const { pathname } = useLocation()

    const paramsString = `/${Object.values(params).join('/')}`
    return handleUseParams({
      ...params,
      resource:
        Object.keys(params).length === 0
          ? pathname.substring(1)
          : decodeURIComponent(pathname.substring(1)).replace(paramsString, ''),
    })
  },
  Prompt(props) {
    const { message, when, setWarnWhen } = props

    const history = useHistory()

    useEffect(() => {
      if (!when) return

      const unblock = history.block((transition: any) => {
        if (window.confirm(message)) {
          setWarnWhen?.(false)
          unblock()
          transition.retry()
        } else {
          history.location.pathname = window.location.pathname
        }
      })
      return unblock
    }, [when, message, history, setWarnWhen])

    return null
  },
  Link,
  routes: [],
  RouterComponent: undefined,
}

const dummyResources = [{ name: '' }]

type Props = {
  dataProviders: Record<DataProviderName, DataProvider>
  i18nProvider?: I18nProvider
  queryClient: QueryClient
}

export function RefineOverride(props: PropsWithChildren<Props>) {
  const { dataProviders, i18nProvider, queryClient, children } = props
  return (
    <Refine
      routerProvider={customRouterProvider}
      // @ts-expect-error any
      dataProvider={dataProviders}
      i18nProvider={i18nProvider}
      options={{
        reactQuery: {
          clientConfig: queryClient,
        },
        disableTelemetry: true,
      }}
      resources={dummyResources}
    >
      {children}
    </Refine>
  )
}
