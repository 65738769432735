import { makeForm } from '@aubade/core/ui-kit'
import type { PublicationForm } from '@aubade/types'

const { Input, useWatch } = makeForm<
  PublicationForm,
  'withNotification' | 'isInternal'
>()

export function NotificationSection() {
  const isInternal = useWatch('isInternal')

  const withNotification = useWatch('withNotification')

  if (isInternal) return <></>
  return (
    <>
      <Input.Checkbox
        name="withNotification"
        label="publications.fields.withNotification"
        inputLabel="publications.title.withNotification"
      />

      {withNotification && (
        <>
          <Input.Text
            name="notificationTitle"
            label="publications.fields.notificationTitle"
            maxLength={50}
            required
          />
          <Input.TextArea
            name="notificationContent"
            label="publications.fields.notificationContent"
            maxLength={100}
            required
          />
        </>
      )}
    </>
  )
}
