import { useEnv } from 'src/adapters/Env'

export function useGetKeycloakUrlAction(redirectUri: string, action: string) {
  const env = useEnv()
  const { KEYCLOAK_CONFIG } = env
  const authority = `${KEYCLOAK_CONFIG.url}/realms/${KEYCLOAK_CONFIG.realm}`

  const url = `${authority}/protocol/openid-connect/auth?client_id=app-crafter-web&redirect_uri=${redirectUri}&response_type=code&scope=openid&kc_action=${action}`
  return url
}
