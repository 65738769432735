import type { TranslationKey } from '@aubade/translation'
import { useTranslate } from '@aubade/translation'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { VStack, useDisclosure, Collapse, Box, HStack } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'

import { Button } from '../Button'
import { Card } from '../Card'
import { Title } from '../Title'

type SharedProps<T> = {
  renderTitle: (item: T, index: number) => ReactNode
  renderForm: (prefix: string, actions: FormActions) => ReactNode
  deleteLabel?: string
}

export type ArrayAccordionInputProps<T, K> = {
  label: TranslationKey
  name: Extract<keyof K, string> & string
  addButtonProps: AddButtonProps
  newValue: () => Partial<T>
} & SharedProps<T>

type AddButtonProps = {
  label: TranslationKey
}
type FormActions = { remove: () => void }

export function ArrayAccordionInput<T, K>(
  props: ArrayAccordionInputProps<T, K>,
) {
  const { label, name, addButtonProps, newValue, ...accordionProps } = props
  const translate = useTranslate()

  const { fields, append, remove } = useFieldArray({
    name: name as never,
    // Name needs to be a cast as const see: https://react-hook-form.com/api/usefieldarray#TypeScript
    // But it is not possible as we have a variable here so cast as never seems to do the trick
  })

  return (
    <>
      <HStack justifyContent="space-between" width="100%">
        <Title.H4 id={label}>
          {translate(label, { count: fields?.length ?? 0 })}
        </Title.H4>
        <Button
          onClick={() => append(newValue())}
          variant="primary"
          label={addButtonProps.label}
        />
      </HStack>
      {fields.map((field, index) => (
        <Accordion<T>
          key={field.id}
          name={`${name}.${index}`}
          index={index}
          actions={{
            remove() {
              remove(index)
            },
          }}
          {...accordionProps}
        />
      ))}
    </>
  )
}

type AccordionProps<T> = {
  name: string
  index: number
  actions: FormActions
} & SharedProps<T>

function Accordion<T>(props: AccordionProps<T>) {
  const { name, index, renderTitle, renderForm, actions, deleteLabel } = props
  const value = useWatch({
    name,
  })

  const { isOpen, onToggle } = useDisclosure()

  return (
    <Card padding={0} overflow="hidden">
      <HStack
        padding={2}
        cursor="pointer"
        onClick={onToggle}
        _hover={{ bg: 'gray.50' }}
        justifyContent="space-between"
        alignItems="stretch"
      >
        {renderTitle(value as T, index)}
        {isOpen ? (
          <ChevronUpIcon w={6} h={6} />
        ) : (
          <VStack alignItems="flex-end">
            <ChevronDownIcon w={6} h={6} />
            <Button
              variant="primary"
              isDangerous
              onClick={actions.remove}
              label={deleteLabel ?? 'actions.delete'}
            />
          </VStack>
        )}
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Box padding={2}>{renderForm(name, actions)}</Box>
      </Collapse>
    </Card>
  )
}
