// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const RadioOff = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#FFFFFC" />
  </svg>
)

const Memo = memo(RadioOff)
export default Memo
