import { Paragraph, Button } from '@aubade/core/ui-kit'
import { IconRecherche } from '@aubade/design/graphics'
import type { CampaignApiList } from '@aubade/types'
import { VStack, HStack } from '@chakra-ui/react'
import type { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useUrls } from 'src/App'

type ColumnProps = {
  row: CampaignApiList
}

function NameSection(props: ColumnProps) {
  const { row } = props
  const { name, agencyNames, societyNames, isNational } = row

  const targets = isNational
    ? 'campaign.list.isNational'
    : [...(agencyNames ?? []), ...(societyNames ?? [])]
        .filter(Boolean)
        .join(' - ')
  return (
    <VStack
      width="full"
      height="60px"
      overflow="hidden"
      alignItems={'flex-start'}
      justifyContent={'center'}
      position="relative"
    >
      <Paragraph fontWeight="bold" text={name} ellipsis />
      <Paragraph text={targets} ellipsis />
    </VStack>
  )
}
function Actions(props: ColumnProps) {
  const { row } = props
  const { id } = row
  const urls = useUrls()
  return (
    <HStack
      gap="10px"
      width="full"
      height="full"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button
        variant="circleList"
        leftIcon={IconRecherche}
        to={urls.aubade().campaignEdit(id)}
      />
    </HStack>
  )
}

export function useGetColumns() {
  return useMemo(() => {
    return [
      {
        header: '',
        accessorKey: 'name',
        cell(cellProps) {
          const { row } = cellProps

          return <NameSection row={row.original} />
        },
      },
      {
        header: '',
        accessorKey: 'content',
        cell() {
          return (
            <HStack
              width="full"
              height="full"
              justifyContent="center"
              alignItems="center"
            >
              <Paragraph text={'messages.list.internalMessage'} />
            </HStack>
          )
        },
      },
      {
        header: '',
        accessorKey: 'id',
        cell(cellProps) {
          const { row } = cellProps

          return <Actions row={row.original} />
        },
      },
    ] satisfies ColumnDef<CampaignApiList>[]
  }, [])
}
