import type { BackendModule, CallbackError } from 'i18next'
import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export * from './useTranslate'
export { useI18nProvider } from './i18nProvider'
export { Trans } from 'react-i18next'

const Backend: BackendModule = {
  type: 'backend',
  init() {},
  read(
    language: string,
    namespace: string,
    callback: (errorValue: CallbackError, translations: any) => void,
  ) {
    import(`./locales/${language}/${namespace}.locale.json`)
      .then((resources) => {
        callback(null, resources)
      })
      .catch((error) => {
        callback(error, null)
      })
  },
}

await i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['fr'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.locale.json', // locale files path
    },
    defaultNS: 'common',
    nsSeparator: '::',
    fallbackLng: ['fr'],
  })

export default i18n
