import { QueryOne, unit } from '@aubade/core/ui-kit'
import type { ExtendedOneConversation } from '@aubade/types/index'
import { VStack, HStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/data-provider/react'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ConversationBody } from './ConversationBody'
import { ConversationHeader } from './ConversationHeader'
import { ConversationInfos } from './ConversationInfos'

type ConversationProps = {
  id: string
}

export function ConversationDetail(props: ConversationProps) {
  const { id } = props

  const infoState = useState<boolean>(false)
  const [showInfo, setShowInfo] = infoState

  const [searchParams, setSearchParams] = useSearchParams()

  const queryClient = useQueryClient()

  async function onCloseModal() {
    searchParams.delete('page')
    searchParams.delete('id')
    setSearchParams(searchParams)
    await queryClient.invalidateQueries({
      predicate: (query) => {
        const { queryKey } = query
        return queryKey.includes('summary/conversations')
      },
    })
  }

  return (
    <HStack
      gap={unit('10')}
      width="full"
      height="full"
      maxHeight="full"
      justifyContent={'right'}
      alignItems={'center'}
      onClick={onCloseModal}
    >
      <QueryOne<ExtendedOneConversation>
        iri={toHydraId('conversations', id)}
        children={(conversation) => {
          const { isInformational = false } = conversation

          return (
            <>
              <VStack
                width="800px"
                height="full"
                maxHeight="full"
                justifyContent={'center'}
                alignItems={'center'}
                onClick={(e) => e.stopPropagation()}
              >
                <ConversationHeader
                  conversation={conversation}
                  infoState={infoState}
                  isInformational={isInformational}
                />
                <ConversationBody
                  conversation={conversation}
                  infoState={infoState}
                  isInformational={isInformational}
                />
              </VStack>
              {showInfo && (
                <ConversationInfos
                  conversation={conversation}
                  onClick={() => setShowInfo(false)}
                />
              )}
            </>
          )
        }}
      />
    </HStack>
  )
}
