// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const IconMessage = (props: SVGProps<SVGSVGElement> & { slot?: string }) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.9009 0.000162008H13.0984C5.845 0.000162008 0 5.77202 0 12.9347V28.0945C0 33.7971 3.73235 38.6648 8.94368 40.3335C10.7745 40.8899 11.9716 42.559 11.9716 44.4362V48.6089C11.9716 49.7217 13.3097 50.4169 14.2253 49.7217L24.5071 41.8635C25.2818 41.3071 26.1974 40.9593 27.1833 40.9593H36.9016C44.1545 41.0292 50 35.2572 50 28.0943V12.9345C50 5.77186 44.155 0 36.9016 0L36.9009 0.000162008Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(IconMessage)
export default Memo
