import { useQueryParamsState, drawerQueryParams } from '@aubade/core/libs'
import { FormDrawer } from '@aubade/core/ui-kit'

import { ConversationCreate } from './ConversationCreate'
import { ConversationDetail } from './ConversationDetail'

export function Conversation() {
  const [modalState] = useQueryParamsState(drawerQueryParams)
  const { page, id } = modalState

  const matchCreate = page === 'create'
  const match = Boolean(matchCreate || id)

  const variant = matchCreate ? 'solid' : 'ghost'
  const size = matchCreate ? 'lg' : 'full'

  return (
    <FormDrawer isOpenProps={Boolean(match)} variant={variant} size={size}>
      {matchCreate && <ConversationCreate />}
      {id && <ConversationDetail id={id} />}
    </FormDrawer>
  )
}
