import { Button } from '@aubade/core/ui-kit'
import { IconPlus } from '@aubade/design/graphics'
import { useSearchParams } from 'react-router-dom'

export function CreateConversationButton() {
  const [searchParams, setSearchParams] = useSearchParams()

  function onClick() {
    searchParams.append('page', 'create')

    setSearchParams(searchParams)
  }

  return (
    <Button
      variant="primary"
      label={'actions.newConversation'}
      leftIcon={IconPlus}
      onClick={onClick}
    />
  )
}
