import { appSetup } from '@aubade/core/adapters'

import type { IEnv } from './adapters/Env'
import { config } from './config'

export function setup(env: IEnv) {
  appSetup({
    env,
    sentryConfig: env.SENTRY_DSN
      ? {
          dsn: env.SENTRY_DSN,
          env: env.APP_ENV,
          release: config.APP_RELEASE,
        }
      : undefined,
  })
}
