import { makeForm, booleanTransformer } from '@aubade/core/ui-kit'
import { useTranslate } from '@aubade/translation'
import type { PublicationForm } from '@aubade/types'
import { Box, VStack } from '@chakra-ui/react'
import { config } from 'src/config'

import { CategoryPicker } from '../../../components/Filters/CategoryFilter'

const { Input, useWatch, useFormContext } = makeForm<
  PublicationForm,
  'allCategories' | 'jobs' | 'isInternal'
>()
export function CategorySection() {
  const { setValue } = useFormContext()
  const isInternal = useWatch('isInternal')
  const translate = useTranslate()

  const allCategories = useWatch('allCategories')

  const { features } = config

  if (isInternal) return <></>

  return (
    <VStack gap={4} width="full" alignItems={'flex-start'}>
      <Input.RadioButtons<boolean>
        name="allCategories"
        label="publications.fields.allCategories"
        onChange={() => {
          setValue('jobs', [])
        }}
        transformer={booleanTransformer}
        options={{
          true: translate('publications.fields.allCategories.true'),
          false: translate('publications.fields.categories'),
        }}
      />
      {allCategories === false && (
        <Box width="full">
          <Box padding={2} width="full">
            <CategoryPicker name="jobs" />
            <Box height="16px" />
          </Box>
        </Box>
      )}

      <>
        <Input.Checkbox
          label="publications.fields.proAubade"
          name="isProAubade"
          onChange={() => {
            setValue('isInternal', false)
          }}
        />
        {features.eACard && (
          <Input.Checkbox
            label="publications.fields.hasEaCard"
            name="hasEaCard"
            onChange={() => {
              setValue('isInternal', false)
            }}
          />
        )}
      </>
    </VStack>
  )
}
