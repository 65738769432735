import { Box } from '@chakra-ui/react'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import type { BaseRecord, GetListResponse } from '../../../adapters'
import { isReactNode } from '../../../libs'
import { Paragraph } from '../../Paragraph'
import { useIsBusy } from '../../useIsBusy'
import type { PickerRenderOptionsProps } from '../Picker'
import { Picker } from '../Picker'

export type DataPickerOptionsProps<
  TQueryFnData extends BaseRecord,
  TValue = TQueryFnData['@id'],
> = {
  getQuery: (
    options: Pick<PickerRenderOptionsProps<TValue>, 'search' | 'values'>,
  ) => UseQueryOptions<GetListResponse<TQueryFnData>>
  renderOption: (
    option: TQueryFnData,
  ) => ReactNode | DataPickerRenderOptionProps
}

type Props<T extends BaseRecord> = PickerRenderOptionsProps<T['@id']> &
  DataPickerOptionsProps<T, T['@id']>

export function DataPickerOptions<T extends BaseRecord>(props: Props<T>) {
  const { getQuery, onAdd, onRemove, values, ...rest } = props
  return (
    <RawDataPickerOptions<T>
      {...rest}
      values={values.map((iri) => {
        return { '@id': iri } as T
      })}
      getQuery={(params) => {
        return getQuery({ ...params, values }) as any
      }}
      onAdd={(item) => onAdd(item['@id'])}
      onRemove={(item) => onRemove(item['@id'])}
    />
  )
}

type RawProps<T extends BaseRecord> = PickerRenderOptionsProps<T> &
  DataPickerOptionsProps<T, T>

export function RawDataPickerOptions<T extends BaseRecord>(props: RawProps<T>) {
  const {
    getQuery,
    renderOption,
    onAdd,
    values,
    search,
    withoutEmptyText = false,
  } = props

  const [debouncedSearch] = useDebounce(search, 200)
  const isDebounced = debouncedSearch !== search
  useIsBusy(isDebounced)

  const queryOptions = useMemo(
    () => getQuery({ values, search: debouncedSearch }),
    [getQuery, values, debouncedSearch],
  )

  const query = useQuery({
    ...queryOptions,
    suspense: true,
    useErrorBoundary: true,
    keepPreviousData: false,
  })

  if (!query.data) return null

  const list = query.data.data
  const ids = values.map((x) => x['@id'])
  const filteredData = list.filter((option) => !ids.has(option['@id']))

  if (!filteredData.length && !withoutEmptyText) {
    return (
      <Box paddingX={3}>
        <Paragraph color={'grey.500'} text={'search.noResult'} />
      </Box>
    )
  }

  return (
    <>
      {list.map((option, index) => {
        if (ids.has(option['@id'])) return null

        const renderedOption = renderOption(option)
        const renderedOptionNode = isReactNode(renderedOption) ? (
          renderedOption
        ) : (
          <DataPickerRenderOption {...renderedOption} />
        )

        return (
          <Picker.Option
            key={option['@id']}
            id={option['@id']}
            index={index}
            onClick={() => onAdd(option)}
          >
            {renderedOptionNode}
          </Picker.Option>
        )
      })}
    </>
  )
}

type DataPickerRenderOptionProps = {
  title: ReactNode
  subTitle?: ReactNode
}
export function DataPickerRenderOption(props: DataPickerRenderOptionProps) {
  const { title, subTitle } = props

  return (
    <>
      <Paragraph
        size="sm"
        fontWeight="bold"
        color={'inherit'}
        whiteSpace={'pre-line'}
      >
        {title}
      </Paragraph>
      <Paragraph
        size="xs"
        fontWeight="normal"
        opacity={0.5}
        color={'inherit'}
        whiteSpace={'pre-line'}
      >
        {subTitle}
      </Paragraph>
    </>
  )
}
