import type { GetRowProps } from '@aubade/core/ui-kit'
import { Button, Surface, unit } from '@aubade/core/ui-kit'
import { IconPlus } from '@aubade/design/graphics'
import type { ExtendedTypesenseList } from '@aubade/types'
import { useCallback } from 'react'
import { useUrls } from 'src/App'
import { ResourcePageLayout } from 'src/components/ResourcePageLayout'

import { ListHeader } from '../../components/ListHeader'
import { MultiList } from '../../components/MultiList'

import { PublicationEdit } from './CreateEdit'
import { usePublicationFilters } from './filters'
import { FilterBar } from './filters/FilterForm'
import type { ListFilterProps } from './List/index'
import { RightPanel, useGetColumns } from './List/index'

const templateColumns = '60px 360px 100px 1fr'
const resource = 'publications'
const scope = 'publications'

export function PublicationsList() {
  const listFilters = usePublicationFilters('publications')
  return (
    <ResourcePageLayout>
      <PublicationList listFilters={listFilters} />
      <RightPanel listFilters={listFilters} />
      <PublicationEdit />
    </ResourcePageLayout>
  )
}

function PublicationList(props: ListFilterProps) {
  const { listFilters } = props
  const { filtersStore } = listFilters

  const columns = useGetColumns()
  const urls = useUrls()

  return (
    <Surface
      direction={'column'}
      overflowY="auto"
      gap={unit('40')}
      maxHeight={'full'}
      minHeight={'full'}
      width="full"
      paddingX={unit('10')}
      paddingY={unit('40')}
    >
      <ListHeader
        scope={scope}
        mainAction={
          <Button
            variant="primary"
            label={'actions.newPublication'}
            leftIcon={IconPlus}
            to={urls.aubade().publicationsCreate()}
          />
        }
        filters={
          <FilterBar
            onChange={filtersStore.setState}
            filterState={filtersStore.state}
            resource={resource}
          />
        }
      />
      <MultiList<ExtendedTypesenseList>
        templateColumns={templateColumns}
        listFilters={listFilters}
        columns={columns}
        resource={resource}
        getRowProps={useCallback<GetRowProps<ExtendedTypesenseList>>(
          (row) => {
            return {
              to: urls.aubade().publicationsEdit(row.id),
            }
          },
          [urls],
        )}
      />
    </Surface>
  )
}
