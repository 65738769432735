class UnexpectedCaseError extends Error {
  constructor(msg: string) {
    super(`Aborted early because: "${msg}"`)
    this.name = 'UnexpectedCaseError'
  }
}

export function fail(msg: string): never {
  throw new UnexpectedCaseError(msg)
}

export function warn<T>(msg: string, defaultVal: T): T {
  // eslint-disable-next-line no-console
  if ((console as any).original) {
    // eslint-disable-next-line no-console
    ;(console as any).original?.warn(msg)
  } else {
    console.warn(msg)
  }
  return defaultVal
}
