import type { DataProvider } from '@aubade/core/adapters'
import { PublicationLinkEnum } from '@aubade/types'
import type {
  ExtendedUser,
  PublicationApiRead,
  PublicationForm,
  PublicationApiWrite,
} from '@aubade/types'
import type { Document } from '@aubade/types/api'
import { cleanHydraId } from '@nartex/data-provider/react'
import type { Merge } from '@nartex/stdlib'
import type { SearchResponse } from '@nartex/typesense'

import { encodePayloadApiToTypesense } from '../index'

type ExtendedApiPublication = Merge<
  PublicationApiRead,
  {
    documents?: Document.Read[]
    fullAuthor?: ExtendedUser
    publishedAt?: Date
  }
>

export function PublicationsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-expect-error any
    async getOne(params) {
      const { id } = params
      const publications = await dataProvider.custom!<PublicationApiRead>({
        ...params,
        method: 'get',
        url: `${id}/find`,
      })
      const { data } = publications
      const { documents, author } = data
      const ids = documents.map((document) => cleanHydraId(document))

      const { data: fullDocuments } =
        await dataProvider.getMany!<Document.Read>({
          ...params,
          resource: 'documents',
          ids,
        })

      const { data: fullAuthor } = await dataProvider.getOne<ExtendedUser>({
        ...params,
        resource: 'users',
        id: author,
      })

      const extendedApiPublication = {
        ...data,
        publishedAt: data.publishedAt ? new Date(data.publishedAt) : undefined,
        documents: fullDocuments,
        fullAuthor,
      } satisfies ExtendedApiPublication

      const publicationForm = fromApiToForm(extendedApiPublication)

      return {
        ...publications,
        data: publicationForm,
      }
    },
    // @ts-expect-error any

    update(params) {
      const { id } = params

      const encodedVars = fromFormToApi(
        params.variables as unknown as PublicationForm,
      )

      return dataProvider.custom!<PublicationApiWrite, PublicationApiWrite>({
        ...params,
        method: 'put',
        url: `publications/${id}/update`,
        payload: encodedVars,
      })
    },
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'publications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        url: `publications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },

    // @ts-expect-error any

    create(params) {
      const encodedVars = fromFormToApi(
        params.variables as unknown as PublicationForm,
      )

      return dataProvider.create<PublicationApiWrite>({
        ...params,
        resource: 'publications/create',
        variables: encodedVars,
      })
    },
  }
}

function fromFormToApi(data: PublicationForm): PublicationApiWrite {
  const { agencies, societies, isNational } = data

  const targetSocieties = isNational ? [] : (societies ?? undefined)
  const targetAgencies = isNational ? [] : (agencies ?? undefined)

  return {
    ...data,
    targetSocieties,
    targetAgencies,
  }
}

function fromApiToForm(data: ExtendedApiPublication): PublicationForm {
  const {
    targetAgencies,
    targetSocieties,
    societyId,
    toolId,
    url,
    urlLabel,
    jobs,
    publishedAt,
  } = data

  let linkType = PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK']

  if (toolId) {
    linkType = PublicationLinkEnum['enum./publicationlink/TOOL_LINK']
  }
  if (url || urlLabel) {
    linkType = PublicationLinkEnum['enum./publicationlink/EXTERNAL_LINK']
  }

  const hasJobs = Boolean(jobs && jobs.length > 0)

  const planify = Boolean(
    publishedAt && new Date(publishedAt).getTime() > new Date().getTime(),
  )

  return {
    ...data,
    planify,
    linkType,
    agencies: targetAgencies,
    societies: targetSocieties,
    isAgencies: Boolean(societyId),
    allCategories: Boolean(!hasJobs),
  }
}
