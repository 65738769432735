import type { AccordionItemProps } from '@aubade/core/ui-kit'
import { Accordion, makeForm, ParentCheckBox } from '@aubade/core/ui-kit'
import { HStack } from '@chakra-ui/react'
import { toHydraId } from '@nartex/api-platform'
import { useMemo, type PropsWithChildren, useEffect } from 'react'

type FormValues = {
  societies?: string[]
  agencies?: string[]
}

const { Input, useWatch } = makeForm<FormValues, 'agencies' | 'societies'>()

export function AccordionInput(
  props: PropsWithChildren<
    AccordionItemProps & {
      id: string
      onChange?: () => void
      disabled?: boolean
      setSelectedParent?: (id: string | undefined) => void
      single?: boolean
      societyAgencies?: any[]
      readOnly?: boolean
    }
  >,
) {
  const {
    id,
    label,
    onChange,
    disabled = false,
    single = false,
    setSelectedParent,
    societyAgencies,
    readOnly,
  } = props
  const agenciesList = societyAgencies
  const formSocieties = useWatch('societies')
  const formAgencies = useWatch('agencies')

  const checkTargets = useMemo(() => {
    const arrayAgencies = formAgencies
    const arraySocieties = formSocieties

    const hasSociety = Boolean(arraySocieties && arraySocieties.length > 0)
    const hasAgencies = Boolean(arrayAgencies && arrayAgencies.length > 0)

    return {
      hasSociety,
      hasAgencies,
    }
  }, [formSocieties, formAgencies])

  useEffect(() => {
    if (!checkTargets?.hasAgencies) {
      setSelectedParent?.(undefined)
    }
    if (single && !checkTargets?.hasSociety) {
      setSelectedParent?.(undefined)
    }
    if (
      single &&
      checkTargets?.hasSociety &&
      formSocieties &&
      formSocieties.length > 0
    ) {
      const arraySocieties = formSocieties
      setSelectedParent?.(arraySocieties![0])
    }
    if (
      single &&
      !checkTargets?.hasSociety &&
      formAgencies &&
      formAgencies.length > 0
    ) {
      const arrayAgencies = formAgencies
      setSelectedParent?.(arrayAgencies![0])
    }
  }, [
    checkTargets?.hasAgencies,
    checkTargets?.hasSociety,
    formAgencies,
    formSocieties,
    id,
    setSelectedParent,
    single,
  ])

  return (
    <HStack width="full">
      <Accordion.Item
        label={''}
        rightElement={
          <ParentCheckBox
            name="societies"
            onFilterChange={onChange}
            readOnly={readOnly}
            children={(onValueChange, valueSet, isDisabled) => {
              return (
                <Input.CheckBoxArray
                  label={label ?? ' '}
                  readOnly={readOnly}
                  disabled={Boolean(
                    isDisabled || disabled || checkTargets?.hasAgencies,
                  )}
                  checked={valueSet.has(toHydraId('societies', id))}
                  onChange={(val) => {
                    onValueChange(val, toHydraId('societies', id))
                  }}
                />
              )
            }}
          />
        }
      >
        <ParentCheckBox
          name="agencies"
          onFilterChange={onChange}
          readOnly={readOnly}
          children={(onValueChange, valueSet, isDisabled) => {
            return (
              <>
                {agenciesList?.map((agn) => {
                  if (formAgencies?.includes(agn['@id'])) {
                    setSelectedParent?.(id)
                  }

                  const checked = valueSet.has(toHydraId('agencies', agn.id))
                  return (
                    <Input.CheckBoxArray
                      key={agn.id}
                      checked={checked}
                      label={agn.name!}
                      onChange={(val) =>
                        onValueChange(val, toHydraId('agencies', agn.id))
                      }
                      disabled={
                        Boolean(
                          isDisabled ||
                            (formAgencies &&
                              formAgencies.length > 0 &&
                              !formAgencies.includes(agn['@id'])) ||
                            (single &&
                              formAgencies &&
                              formAgencies.length > 0 &&
                              !formAgencies.includes(agn['@id'])),
                        ) || checkTargets?.hasSociety
                      }
                    />
                  )
                })}
              </>
            )
          }}
        />
      </Accordion.Item>
    </HStack>
  )
}
