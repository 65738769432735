import type {
  CrudFilters,
  DataProvider,
  LogicalFilter,
} from '@aubade/core/adapters'
import { encodePagination, type SearchResponse } from '@nartex/typesense'
import { config } from 'src/config'

export function CollaboratersProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const { filters, metaData, pagination } = params

      const encodedPagination = encodePagination(pagination)
      const { page } = encodedPagination

      const searchResponse = await dataProvider.custom!<SearchResponse<any>>({
        method: 'post',
        url: `/users/associates?page=${page}`,
        payload: {
          ...recordFilters(filters),
          ...encodedPagination,
        },
        headers: metaData?.headers,
      })

      const result = searchResponse.data

      const data = result?.hits?.map((hit) => hit.document) ?? []

      const { lastLogin } = config.features

      const filteredDatas = data.filter((associate) => {
        if (lastLogin) {
          return associate.lastLoginAt !== null
        } else {
          return associate
        }
      })

      return {
        data: filteredDatas,
        total: result?.found ?? 0,
        raw: { typesense: result },
      }
    },
  }
}
export function recordFilters<T>(filters: CrudFilters<T> | undefined) {
  if (!filters || !filters.length) return undefined
  return filters.reduce((res, element) => {
    if ('q' in element) {
      res.q = element.q
    } else {
      res[(element as LogicalFilter<T>).field] = (
        element as LogicalFilter<T>
      ).value
    }
    return res
  }, {} as any)
}
