import type { Merge } from '@aubade/core/libs'
import type { TranslationKey } from '@aubade/translation'
import { Box, Flex, FormLabel, Wrap } from '@chakra-ui/react'
import type { ForwardedRef } from 'react'
import { forwardRef, memo, useId } from 'react'

import { getButtonBehavior } from '../../Button'
import { Paragraph } from '../../Paragraph'
import { unit } from '../../utils'
import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'
import { getSelectOptions } from '../getSelectOptions'
import { ReadOnlyInputRender } from '../ReadOnlyInputs'

export type RangeSelectProps = Merge<
  BaseInputProps<number, string>,
  {
    label: TranslationKey
    defaultValue?: number
    options: number[] | [number, string][]
  }
>

export const RangeSelect = memo(
  forwardRef(function (
    props: RangeSelectProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { label, options } = props

    const { field, wrap } = useBaseInput<number | undefined>({
      ...props,
      label: undefined,
      transformer: {
        read(value) {
          if (value === null || value === undefined) {
            return undefined
          }
          return value
        },
        write(value) {
          if (value === null || value === undefined) return null
          return Number(value)
        },
      },
    })
    const { value, id, onChange } = field

    const normalizedOptions = getSelectOptions(options)

    return wrap(
      field.readOnly ? (
        <ReadOnlyInputRender {...props} field={field as any} />
      ) : (
        <Flex ref={ref} alignItems="center" gap={1}>
          <FormLabel as="label" margin={0} htmlFor={id} width={unit('96')}>
            <Paragraph text={label} size="xs" fontWeight="normal" />
          </FormLabel>

          <Wrap paddingBottom={1} spacing={1}>
            {normalizedOptions.map(([optionValue, optionLabel]) => {
              const isChecked = optionValue === value
              return (
                <Option
                  key={optionValue}
                  label={optionLabel}
                  isChecked={isChecked}
                  onClick={() => {
                    if (isChecked) {
                      return onChange(null)
                    }
                    onChange(optionValue)
                  }}
                />
              )
            })}
          </Wrap>
        </Flex>
      ),
    )
  }),
)

type OptionProps = {
  label: string | number
  isChecked: boolean
  onClick: () => void
}

const Option = forwardRef<HTMLDivElement, OptionProps>(
  function Option(props, ref) {
    const { label, isChecked, onClick } = props

    function getSelectedStyle() {
      if (isChecked) {
        return {
          backgroundColor: 'primary.500',
          borderColor: 'primary.500',
        }
      }
      return {
        backgroundColor: 'white',
        borderColor: 'greyscale.200',
      }
    }

    const id = useId()

    return (
      <Box
        {...getButtonBehavior({ onClick })}
        as="label"
        id={id}
        ref={ref}
        borderRadius="md"
        cursor="pointer"
        border="1px solid"
        paddingX={3}
        paddingY={unit('9')}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={unit('42')}
        {...getSelectedStyle()}
      >
        <Paragraph
          color={isChecked ? 'white.500' : undefined}
          text={String(label)}
        />
      </Box>
    )
  },
)
