import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react'
import { useTranslate } from '@pankod/refine-core'
import type { FocusEvent, ChangeEvent } from 'react'
import { useMemo, useState, useId, useCallback } from 'react'
import type { FieldElement, FieldError } from 'react-hook-form'

import { useEvent, useIsHidden, useLowPriorityCallback } from '../../../libs'
import { Paragraph } from '../../../ui-kit'

import type {
  UseBaseInputResult,
  UseBaseInputParams,
  UseBaseInputOptions,
  Transformer,
} from './types'
import { noopTransformer } from './types'

type FieldValue<T> = T | (null | undefined)
type FieldUpdate<T> = (prev: FieldValue<T>) => FieldValue<T>
type FieldOnChangeParam<T> =
  | ChangeEvent
  | string
  | FieldValue<T>
  | FieldUpdate<T>

export function useBaseInput<
  Outer,
  Inner = Outer,
  InnerDeep = Inner,
  Element extends HTMLElement = HTMLElement,
>(
  params: UseBaseInputParams<Outer, Inner, Element>,
  options?: UseBaseInputOptions<Inner, InnerDeep>,
): UseBaseInputResult<InnerDeep, Element> {
  const {
    label,
    name,
    value,
    error,
    disabled,
    readOnly,
    required,
    onFocus,
    onBlur,
    ref,
    helperText,
    id: givenId,
    shouldUpdate,
  } = params
  const onChange = useLowPriorityCallback(params.onChange ?? (() => {}))

  const { baseTransformer, noLocalValue } = options ?? {}

  const generatedId = useId()
  const id = givenId ?? generatedId
  const translate = useTranslate()

  const isHidden = useIsHidden()

  const transformer = useComposeTransformers(
    params.transformer ?? noopTransformer,
    baseTransformer ?? noopTransformer,
  )

  const { read: transformerRead } = transformer
  const externalValue = useMemo(
    () => transformerRead(value) as InnerDeep,
    [transformerRead, value],
  )

  const [localValue, setLocalValue] = useState(externalValue)
  const [isFocused, setIsFocused] = useState(false)
  function getDisplayedValue() {
    if (noLocalValue) return externalValue
    if (isFocused) return localValue
    return externalValue
  }

  const fieldError: FieldError | undefined = useMemo(() => {
    if (!error) return
    // ignore nested errors
    if (Array.isArray(error)) return
    if (typeof error === 'object') return error
    return { message: error, type: 'value' }
  }, [error])

  /*
    wrap est une fonction de décoration au lieu d'un composant.
    Si on l'utilise en tant que composant on s'expose à des problèmes avec React quand l'identité de la fonction qui défini le composant change
  */
  const wrap = useCallback<UseBaseInputResult<Inner>['wrap']>(
    (children, wrapOptions) => {
      const { width = 'full' } = wrapOptions ?? {}

      if (isHidden) return null
      return (
        <FormControl
          height={'full'}
          width={width}
          isReadOnly={readOnly}
          isDisabled={disabled}
          isRequired={Boolean(required)}
          isInvalid={Boolean(fieldError)}
          margin={0}
        >
          {label && (
            <FormLabel marginBottom={'5px'} htmlFor={id}>
              <Paragraph text={translate(label)} size="xs" />
            </FormLabel>
          )}
          {children}
          {fieldError && (
            <FormErrorMessage color="error.dark">
              {translate(fieldError.message || `errors.${fieldError.type}`)}
            </FormErrorMessage>
          )}
          {helperText && (
            <FormHelperText>
              <Paragraph size="sm" color="greyDark.500" text={helperText} />
            </FormHelperText>
          )}
        </FormControl>
      )
    },
    [
      id,
      readOnly,
      disabled,
      required,
      label,
      translate,
      fieldError,
      helperText,
      isHidden,
    ],
  )

  return {
    id,
    wrap,
    field: {
      ref,

      id,
      label: label ?? '',
      name: name ?? '',
      value: getDisplayedValue(),

      error: fieldError,
      disabled: Boolean(disabled),
      readOnly: Boolean(readOnly),
      required: Boolean(required),

      onChange: useEvent((eventOrValue: FieldOnChangeParam<InnerDeep>) => {
        const newLocalValue = getEventValue(eventOrValue, getDisplayedValue())
        if (shouldUpdate?.(newLocalValue) ?? true) {
          setLocalValue(newLocalValue)

          const newExternalValue = transformer.write(newLocalValue) as Outer
          onChange?.(newExternalValue)
        }
      }),

      onBlur: useEvent((event: FocusEvent<Element>) => {
        setIsFocused(false)
        onBlur?.(event)
      }),

      onFocus: useEvent((event?: FocusEvent<Element>) => {
        onFocus?.(event)

        if (isFocused) return
        setIsFocused(true)
        setLocalValue(externalValue)
      }),
    },
  }
}

type Event = { target: any }

function getEventValue<T>(
  eventOrCallbackOrValue: FieldOnChangeParam<T>,
  currentValue: FieldValue<T>,
) {
  if (!eventOrCallbackOrValue) {
    return eventOrCallbackOrValue as FieldValue<T>
  }

  if (typeof eventOrCallbackOrValue === 'function') {
    return (eventOrCallbackOrValue as FieldUpdate<T>)(currentValue)
  }

  if (
    typeof eventOrCallbackOrValue === 'object' &&
    (eventOrCallbackOrValue as Event).target
  ) {
    if (isCheckBoxInput((eventOrCallbackOrValue as Event).target)) {
      return (eventOrCallbackOrValue as Event).target.checked
    } else {
      return (eventOrCallbackOrValue as Event).target.value
    }
  }

  return eventOrCallbackOrValue
}

function isCheckBoxInput(element: FieldElement) {
  return element.type === 'checkbox'
}

function useComposeTransformers<Outer, Inner, InnerDeep>(
  tOut: Transformer<Outer, Inner>,
  tIn: Transformer<Inner, InnerDeep>,
): Transformer<Outer, InnerDeep> {
  const { read: tOutRead, write: tOutWrite } = tOut
  const { read: tInRead, write: tInWrite } = tIn

  const read: Transformer<Outer, InnerDeep>['read'] = useMemo(() => {
    return (val) => tInRead(tOutRead(val))
  }, [tOutRead, tInRead])

  const write: Transformer<Outer, InnerDeep>['write'] = useEvent((val) => {
    return tOutWrite(tInWrite(val))
  })

  return useMemo(() => {
    return {
      read,
      write,
    }
  }, [read, write])
}
