import { Flex, Switch, FormLabel } from '@chakra-ui/react'
import type { ForwardedRef } from 'react'
import { forwardRef, memo } from 'react'

import { Paragraph } from '../../Paragraph'
import type { BaseInputProps } from '../controller'
import { useBaseInput } from '../controller'

export type SwitchInputProps = BaseInputProps<boolean> & {
  variant?: 'negative'
}

export const SwitchInput = memo(
  forwardRef(function (
    props: SwitchInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { label, variant, ...rest } = props
    const { id, field, wrap } = useBaseInput<boolean, boolean>({
      ...rest,
    })

    return wrap(
      <Flex
        padding={1}
        width="fit-content"
        display="flex"
        alignItems={'center'}
        justifyContent="center"
        gap={4}
        cursor={props.disabled ? 'not-allowed' : 'pointer'}
        borderRadius="sm"
        _focusWithin={{
          outline: '1px solid',
          outlineColor: 'primary.500',
        }}
      >
        <Switch
          {...field}
          ref={ref}
          id={id}
          disabled={field.readOnly || field.disabled}
          value={String(field.value)}
          isChecked={field.value}
          transition=".2s ease-out"
          variant={variant}
        />
        <FormLabel
          display="flex"
          flexDirection="row"
          htmlFor={id}
          marginBottom={0}
        >
          <Paragraph text={label} fontWeight={'bold'} size="sm" />
        </FormLabel>
      </Flex>,
    )
  }),
)
