import { IconArrowBottom, IconArrowRight } from '@aubade/design/graphics'
import type { TranslationKey } from '@aubade/translation'
import type {
  AccordionButtonProps,
  AccordionProps,
  As,
  StackProps,
} from '@chakra-ui/react'
import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionItem as ChakraAccordionItem,
  Icon,
  VStack,
  useAccordionItemState,
} from '@chakra-ui/react'
import type { ForwardedRef, PropsWithChildren, ReactNode } from 'react'
import { forwardRef, memo, useImperativeHandle, useMemo } from 'react'

import { IsHiddenProvider, useEvent } from '../../libs'
import { Boundary } from '../Boundary'
import { Paragraph } from '../Paragraph'
import { Title } from '../Title'
import { unit } from '../utils'

export type AccordionItemState = ReturnType<typeof useAccordionItemState>

export type AccordionItemProps = {
  label: TranslationKey
  leftIcon?: As
  rightElement?: ReactNode
  size?: 'small' | 'large' | 'paragraph'
  buttonWidth?: AccordionButtonProps['width']
  onClick?: (isExpanded: boolean) => void
} & Pick<StackProps, 'spacing' | 'paddingX' | 'paddingY' | 'padding'>

const AccordionItem = forwardRef(function AccordionItem(
  props: PropsWithChildren<AccordionItemProps>,
  ref: ForwardedRef<AccordionItemState>,
) {
  const {
    label,
    children,
    size = 'paragraph',
    buttonWidth = 'full',
    leftIcon,
    rightElement,
    onClick,
    ...accordionButtonProps
  } = props

  const title = useMemo(() => {
    if (size === 'large') {
      return <Title.H4 fontWeight="bold" text={label} />
    }
    if (size === 'paragraph') {
      return <Paragraph size="sm" text={label} />
    }
    return <Title.H6 fontWeight="normal" text={label} />
  }, [size, label])

  // some optimisation for AccordionButton
  const _hover = useMemo(() => {
    return { color: 'blue.500' }
  }, [])

  // some optimisation for AccordionButton
  const onClickExpanded: () => void = useEvent(() => onClick?.(true))
  const onClickNotExpanded: () => void = useEvent(() => onClick?.(false))

  return (
    <ChakraAccordionItem
      border="none"
      width={'full'}
      children={(renderProps) => {
        const { isExpanded } = renderProps
        return (
          <>
            <AccordionButton
              {...accordionButtonProps}
              margin={0}
              paddingX={0}
              paddingY={1}
              borderRadius="sm"
              width={buttonWidth}
              backgroundColor={'transparent'}
              onClick={() => undefined}
              _hover={_hover}
            >
              {leftIcon && (
                <Icon
                  color="darkGrey.500"
                  width={unit('15')}
                  height={unit('15')}
                  as={leftIcon}
                />
              )}
              <Box flex="1" textAlign="left">
                {rightElement || title}
              </Box>
              <Icon
                color="darkGrey.500"
                width={unit('15')}
                height={unit('15')}
                as={isExpanded ? IconArrowBottom : IconArrowRight}
                onClick={isExpanded ? onClickExpanded : onClickNotExpanded}
              />
            </AccordionButton>
            <Panel isExpanded={isExpanded} ref={ref}>
              <VStack spacing={1} alignItems="flex-start" width="full">
                {children}
              </VStack>
            </Panel>
          </>
        )
      }}
    />
  )
})

type PanelProps = {
  isExpanded?: boolean
}
const Panel = memo(
  forwardRef(function Panel(
    props: PropsWithChildren<PanelProps>,
    ref: ForwardedRef<AccordionItemState>,
  ) {
    const { children, isExpanded } = props

    const accordionState = useAccordionItemState()
    useImperativeHandle(ref, () => accordionState)

    return (
      <AccordionPanel
        paddingTop={5}
        paddingBottom={6}
        onFocusCapture={useEvent(() => {
          if (!isExpanded) {
            accordionState.onOpen()
          }
        })}
      >
        <IsHiddenProvider value={!isExpanded}>
          <Boundary>{children}</Boundary>
        </IsHiddenProvider>
      </AccordionPanel>
    )
  }),
)

export const Accordion = Object.assign(
  memo(function Accordion(props: AccordionProps) {
    return <ChakraAccordion width={'full'} allowMultiple {...props} />
  }),
  { Item: AccordionItem },
)
