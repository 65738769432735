import type {
  DayValue,
  CalendarProps,
} from '@amir04lm26/react-modern-calendar-date-picker'
import type { LogicalFilter } from '@aubade/core/adapters'
import { useList } from '@aubade/core/adapters'
import { compactObj } from '@aubade/core/libs'
import type { Filters } from '@aubade/core/ui-kit'
import { Form, makeForm, useForm } from '@aubade/core/ui-kit'
import type { Typesense } from '@aubade/types/typesense'
import { useMemo } from 'react'
import type { DefaultValues } from 'react-hook-form'
import { dateToDayValue, timeToDate } from 'src/libs/calendarUtils'

import type { PublicationFilterType } from '../../Resources/Publications/filters/types'

const { Input } = makeForm()

type Props<T> = {
  onChange: (filters: Filters) => void
  filterState: DefaultValues<T>
  crudFilters: LogicalFilter<T>[]
  resource: 'notifications' | 'publications'
}
export function CalendarFilter<T extends Filters>(props: Props<T>) {
  const { onChange, filterState, crudFilters, resource } = props

  // @ts-expect-error not a record
  const methods = useForm<PublicationFilterType>({
    defaultValues: useMemo(() => {
      return filterState
    }, [filterState]),
  })

  function onFilterChange() {
    onChange(compactObj(methods.getValues()))
  }

  const crudFiltersWithoutDate = crudFilters.filter(
    (filter) => filter.field !== 'publishedAt',
  )

  const [publications] = useList<Typesense.Publications>({
    resource,
    dataProviderName: 'default',
    filters: crudFiltersWithoutDate,
    hasPagination: true,
    pagination: { pageSize: 250 },
  })

  const daysWithPublications = useMemo(() => {
    return convertToDayValue(publications)
  }, [publications])

  return (
    <Form {...methods}>
      <Input.Calendar
        name="publishedAt"
        onChange={onFilterChange}
        daysWithValues={daysWithPublications}
      />
    </Form>
  )
}

function convertToDayValue(
  records: Typesense.Publications[] | undefined,
): CalendarProps<DayValue>['customDaysClassName'] {
  if (!records) return undefined
  return records
    .filter((rec) => rec.publishedAt)
    .map((record) => {
      const date = timeToDate(new Date(record.publishedAt!).getTime())
      return {
        ...dateToDayValue(new Date(date!)),
        className: 'daysWithPublications',
      }
    })
}
