import {
  useEvent,
  createCombinedStateHook,
  isTruthy,
  createContextFunction,
} from '@aubade/core/libs'
import { useIsBusy, useToastMessage } from '@aubade/core/ui-kit'
import { EXCEPTIONS } from '@aubade/types'
import { useMutation } from '@tanstack/react-query'
import type { ReactNode, RefObject } from 'react'
import { useRef, useEffect } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'

import { getButtonBehavior } from '../../Button'
import type { Option, PickerInstance } from '../types'

import type { SuggestOptionProps } from './SuggestOption'

const {
  useCombinedStateRoot: useOptions,
  useSetChildState: useRegisterOption,
} = createCombinedStateHook<Option[], Option>(
  function combineOptions(elements) {
    const values = Object.values(elements).filter(isTruthy)

    if (process.env.NODE_ENV === 'development') {
      const indexes = new Set(values.map((val) => val.index))
      if (indexes.size !== values.length) {
        throw Error('Each option index should be unique')
      }
    }

    return values.sort((a, b) => a.index - b.index)
  },
)

const [PickerInstanceProvider, usePickerInstance] =
  createContextFunction<PickerInstance>('Picker Instance')

export type RenderSuggestOptionProps = ReturnType<typeof usePickerOption>
export function usePickerOption(option: SuggestOptionProps) {
  const ref = useRef<HTMLElement>(null)

  const { highlightedId, onClose } = usePickerInstance()
  const isHighlighted = highlightedId === option.id

  useEffect(() => {
    if (isHighlighted) {
      ref.current?.scrollIntoView({ block: 'nearest', behavior: 'auto' })
    }
  }, [isHighlighted, ref])

  useRegisterOption({
    ...option,
    onClick: useEvent(() => {
      ref?.current?.click()
    }),
  })

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async () => {
      return option.onClick?.()
    },
  })

  const toast = useToastMessage()

  const buttonBehavior = getButtonBehavior({
    ...option,
    onClick: useEvent(async () => {
      try {
        await mutateAsync()
      } catch (error: any) {
        if (error?.message !== EXCEPTIONS.SKIP) {
          toast('error', 'notifications.createError')
        }
        return
      }
      onClose()
    }),
  })

  useIsBusy(isLoading)

  return {
    optionState: { isHighlighted, isLoading },
    optionProps: {
      ...buttonBehavior,
      ref: ref as RefObject<any>,
      tabIndex: -1,
    },
  }
}

export function useOptionsController(pickerInstance: PickerInstance) {
  const [options, wrapper] = useOptions()

  const instance = useDeepCompareMemo(() => pickerInstance, [pickerInstance])
  function optionsContextWrapper(children: ReactNode) {
    return wrapper(
      <PickerInstanceProvider value={instance}>
        {children}
      </PickerInstanceProvider>,
    )
  }

  return [options, optionsContextWrapper] as const
}
