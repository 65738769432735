// @ts-nocheck

/* eslint-disable @typescript-eslint/consistent-type-imports */
import * as React from 'react'
import { SVGProps, memo } from 'react'

const ThumbnailTemplateText = (
  props: SVGProps<SVGSVGElement> & { slot?: string },
) => (
  <svg
    width={145}
    height={106}
    viewBox="0 0 145 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={145} height={106} rx={20} fill="#FFFFFC" />
    <rect x={23} y={36} width={100} height={15} rx={3} fill="#F3F5F7" />
    <rect x={23} y={55} width={100} height={15} rx={3} fill="#F3F5F7" />
  </svg>
)

const Memo = memo(ThumbnailTemplateText)
export default Memo
